import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { Button, Modal } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import styles from "./ServiceConfirmationPage.module.css";
import tickPop from "../../../assests/tickPop.png";
import Confetti from "react-confetti";
import confettiIMG from "../../../assests/confettiIMG.png";
import FailedTick from "../../../assests/customDeleteMark.svg";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import bgImg from "../../../assests/bgPayConf.png";
import { _DELETE, _POST } from "../../../common/commonFunc";
import { useDispatch, useSelector } from "react-redux";

interface RouteParams {
  booking_id: string;
  charge_id: string;
}

function ServiceConfirmationPage() {
  const { booking_id, charge_id } = useParams<RouteParams>();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const history = useHistory();
  const dispatch = useDispatch();

  const tap_id = queryParams.get("tap_id");
  const userInfoString = localStorage.getItem("userInfo");
  const userID = userInfoString ? JSON.parse(userInfoString).id : null;

  const data: any = queryParams.get("data");

  const [paymentData, setPaymentData] = useState<any>(null);
  const [isFailureModalVisible, setIsFailureModalVisible] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);

  const showSuccessModal = () => {
    setIsSuccessModalVisible(true);
  };

  const serviceDetails = useSelector((state: any) => state.service);
  console.log("🚀 ~ ServiceConfirmationPage ~ serviceDetails:", serviceDetails);

  useEffect(() => {
    if (!serviceDetails.serviceId) {
      console.log("Waiting for state to be rehydrated...");
      return;
    }

    const apiUrl = `https://testing.theoctopus.ai:8443/payment/getPaymentById?chargeId=${tap_id}&booking_id=${booking_id}`;
    if (tap_id) {
      axios
        .get(apiUrl, {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        })
        .then(async ({ data }: any) => {
          if (data.status === true && data.data.dataInserted === true) {
            const bookSlotData = {
              serviceTimeDuration:
                Number(data.data.service_end_time) -
                Number(data.data.service_start_time),
              start_time: data.data.service_start_time,
              buffer_time: 900,
              end_time: data.data.service_end_time,
              serviceId: data.data.serviceId,
              providerId: data.data.providerId,
              bookingId: booking_id,
            };

            try {
              const bookSlotresponse = await _POST(
                `services/bookSlot`,
                bookSlotData,
                false
              );
              if (bookSlotresponse.status === true) {
                const rescart = await _DELETE(
                  `cart/removeItem/service/${userID}/${data.data.serviceId}`,
                  true,
                  false
                );
                console.log("🚀 ~ .then ~ rescart:", rescart);
                history.push(
                  `/service-booking/${data.data.serviceId}?status=true&start_time=${data.data.service_start_time}&end_time=${data.data.service_end_time}`
                );
              } else {
                history.push(
                  `/service-booking/${serviceDetails.serviceId}?status=false&start_time=${serviceDetails.startTime}&end_time=${serviceDetails.endTime}`
                );
              }
            } catch (error) {
              console.error("Error booking slot:", error);
              history.push(
                `/service-booking/${serviceDetails.serviceId}?status=false&start_time=${serviceDetails.startTime}&end_time=${serviceDetails.endTime}`
              );
            }
          } else {
            history.push(
              `/service-booking/${serviceDetails.serviceId}?status=false&start_time=${serviceDetails.startTime}&end_time=${serviceDetails.endTime}`
            );
          }
        })
        .catch((error) => {
          history.push(
            `/service-booking/${serviceDetails.serviceId}?status=false&start_time=${serviceDetails.startTime}&end_time=${serviceDetails.endTime}`
          );
        });
    }
  }, [booking_id, charge_id, history, tap_id, serviceDetails]);

  console.log(
    "All req",
    booking_id,
    charge_id,
    history,
    tap_id,
    serviceDetails
  );

  const handleSuccessModalClose = () => {
    setIsSuccessModalVisible(false);
    history.push("/service");
  };
  const handleFailureModalClose = () => {
    setIsFailureModalVisible(false);
    history.push("/events");
  };

  return (
    <div
      style={{
        padding: "24px",
        borderRadius: "1px",
        // backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        // border: "1px solid red",
      }}
    >
      {failedMessage === "" && (
        <Dialog
          open={isSuccessModalVisible}
          onClose={handleSuccessModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.successModalContent}
            style={{
              background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={tickPop} alt="" />
            <h2>Thank You!</h2>
            <p>Your booking & order is sent to the service provider</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleSuccessModalClose}
                style={{ marginTop: "24px" }}
              >
                Go to Home
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {failedMessage !== "" && (
        <Dialog
          open={isFailureModalVisible}
          onClose={handleFailureModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.failedModalContent}
            style={{
              // background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={FailedTick} alt="" />
            <h2>Payment Failed</h2>
            <p>{failedMessage}</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleFailureModalClose}
                style={{ marginTop: "24px" }}
              >
                Go to Home
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default ServiceConfirmationPage;
