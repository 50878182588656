import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Breadcrumb, Radio, Button } from "antd";
import { Card, CardContent, Avatar } from "@mui/material";
import { Typography } from "antd/lib";
import ProfileBox from "./ProfileBox";
import Styles from "./Profile.module.css";

import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import { _GET } from "../../../common/commonFunc";
import { useHistory } from "react-router";
import { Content } from "antd/es/layout/layout";
import { DownloadOutlined } from "@mui/icons-material";
import ProfilePictureModal from "./ProfilePictureModal";
import { CameraOutlined } from "@ant-design/icons";
import ProfileInformation from "./ProfileInformation";
import BusinessInformation from "./BusinessInformation";
import StoreInfo from "./StoreInfo";
import ContactPersonInfo from "./ContactPersonInfo";
import PaymentInformation from "./PaymentInformation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { updateUserProfile } from "../../../redux/actions/userActions";
import GenericPage from "./GenericPage";
import EditProfileDetails from "./EditProfile";

const ProfileDetails = ({ updateSelectedKey, avatar, ud }: any) => {
  const [profileData, setProfileData] = useState<any>(null);
  const [error, setError] = useState<any>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory<any>();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const handleProfilePicClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleProfilePictureUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("profile_picture", file);

    try {
      const response = await fetch(
        `https://testing.theoctopus.ai:8443/userProfile/addProfilePicture/${profileData?.id}`,
        {
          method: "POST",
          body: formData,
          headers: {
            "x-access-token": localStorage.getItem("jwtToken") || "",
          },
        }
      );

      if (response.ok) {
        fetchdata();
        const data = await response.json();
        dispatch(updateUserProfile({ profile_pic: data.profile_pic }));
      } else {
        console.error("Upload failed:", response.statusText);
        // Handle errors
      }
    } catch (error) {
      console.error("Error during the upload:", error);
    }
  };

  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const fetchdata = async () => {
    try {
      const response = await _GET(`userProfile/profile`, true);
      if (response.data && response.status === true) {
        setProfileData(response.data);
      } else {
        console.log("No data received from the request");
      }
    } catch (error) {
      console.log(
        "➡️   ~ file: ServiceDetails.tsx:54 ~ fetchdata ~ error:",
        error
      );
      setError(true);
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);

  if (!profileData && !error) {
    return <div>Loading...</div>;
  } else if (error) {
    return (
      <GenericPage
        pageTitle="Profile Details"
        pageDescription="We were unable to fetch the profile details. Please try again later."
      />
    );
  }

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA !important",
        background: "#F5F4FA !important",
      }}
      className={Styles.customLayoutBG}
    >
      <div
        style={{ height: "auto", width: "100%" }}
        className={Styles.parentContentdiv}
      >
        {/* <div className={Styles.pdLeftDiv}>
          <ProfileInformation
            profileData={profileData}
            handleProfilePicClick={handleProfilePicClick}
            handleProfilePictureUpload={handleProfilePictureUpload}
            isModalOpen={isModalOpen}
            handleModalClose={handleModalClose}
            avatar={avatar}
          />
          <BusinessInformation profileData={profileData} />
          <StoreInfo profileData={profileData} />
        </div>
        <div className={Styles.pdRightDiv}>
          <ContactPersonInfo
            contactPersonInfo={profileData?.contact_person_info}
          />
          <PaymentInformation paymentInfo={profileData?.payment_info} />
        </div> */}
        <EditProfileDetails />
      </div>
    </Layout>
  );
};

export default ProfileDetails;
