import React, { useEffect, useRef, useState } from "react";
import CustomLayout from "../../common/CustomLayout/CustomLayout";
import { Button, Form, Input, Select, notification } from "antd";
import { useHistory } from "react-router";
import {
  _POST,
  agreed_to_terms_and_condition,
  getRole,
  getUserType,
  isDocUpdated,
} from "../../common/commonFunc";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { showSuccessCustomModal } from "../../common/CustomModal/CustomModal";
import Link from "antd/es/typography/Link";
import countries from "../../data/countries.json";
import Styles from "./VerifyOtp.module.css";
import axios from "axios";

const VerifyOtp = (props: any) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const inputRef1 = useRef<any>(null);
  const inputRef2 = useRef<any>(null);
  const inputRef3 = useRef<any>(null);
  const inputRef4 = useRef<any>(null);
  const inputRef5 = useRef<any>(null);
  const inputRef6 = useRef<any>(null);

  const [counter, setCounter] = useState(60);
  const history = useHistory();
  const [isGoogleSignup, setIsGoogleSignup] = useState(
    user?.register_type === "Google"
  );
  const [phoneNumberUpdated, setPhoneNumberUpdated] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    number: user.phone_no || "",
    countryCode: user.country_code || "+973",
  });

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  const updatePhoneNumber = async () => {
    const userID = user.id;
    if (phoneNumber.number === "") {
      notification.error({
        message: "Error",
        description: "Please enter your mobile number",
      });
      return;
    }
    const payload = {
      country_code: phoneNumber.countryCode,
      phone_no: phoneNumber.number,
      mobileNumberWithCountryCode: `${phoneNumber.countryCode}${phoneNumber.number}`,
    };
    const token = user.token;

    try {
      const response = await axios.patch(
        `https://testing.theoctopus.ai:8443/updateMobileNumber?userId=${userID}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": `${token}`,
          },
        }
      );

      if (response.status === 200) {
        notification.success({
          message: "Success",
          description: "Your mobile number has been updated successfully.",
        });

        const updatedUser = {
          ...user,
          phone_no: phoneNumber.number,
          country_code: phoneNumber.countryCode,
        };
        localStorage.setItem("userInfo", JSON.stringify(updatedUser));
        setPhoneNumberUpdated(true);
        setShowUpdateModal(false);
        setCounter(60);
      } else {
        notification.error({
          message: "Error",
          description: "Failed to update mobile number. Please try again.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to update mobile number. Please try again.",
      });
    }
  };

  const inputChangeHandler = (temp: any, index: number) => {
    const setOtpFns = [setOtp1, setOtp2, setOtp3, setOtp4, setOtp5, setOtp6];
    setOtpFns[index](temp);

    if (/[^0-9]/.test(temp) || !temp) {
      return;
    }

    const inputRefs = [
      inputRef1,
      inputRef2,
      inputRef3,
      inputRef4,
      inputRef5,
      inputRef6,
    ];
    if (index < 5) {
      inputRefs[index + 1].current?.focus();
    }
  };

  const handleBackspaceNavigation = (event: any, index: number) => {
    const inputRefs = [
      inputRef1,
      inputRef2,
      inputRef3,
      inputRef4,
      inputRef5,
      inputRef6,
    ];
    if (event.key === "Backspace" && !event.target.value && index > 0) {
      inputRefs[index - 1].current?.focus();
    }
  };

  const verifyMobileHandler = async (values: any) => {
    if (!otp1 || !otp2 || !otp3 || !otp4 || !otp5 || !otp6) {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: "Kindly Enter All the OTP first",
      });
    }
    const otpValue = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;
    const userEmail = user.email;

    if (userEmail) {
      const response = await _POST(
        "verifyMobile",
        { email: userEmail, otp: otpValue },
        false
      );
      if (response.status) {
        const userRole = getRole();
        const userType = getUserType();
        showSuccessCustomModal({
          title: "Your mobile number is verified successfully.",
          smallText:
            "Your Mobile number Verification is successfully completed",
          buttonText: "Continue",
        });

        if (userType === "ShareHolder" && response.data?.is_document_updated) {
          history.push(APPLICATION_ROUTES.LOGIN);
        } else if (response.data?.agreed_to_terms_and_condition) {
          history.push(APPLICATION_ROUTES.LOGIN);
        } else {
          if (userRole === "supplier") {
            history.push(APPLICATION_ROUTES.VERIFY_DOCUMENTS);
          } else if (userRole === "user") {
            history.push(APPLICATION_ROUTES.CATEGORIES);
          } else {
            history.push(APPLICATION_ROUTES.VERIFY_DOCUMENTS);
          }
        }
      } else {
        notification.open({
          type: "error",
          message: "Unable to verify OTP",
          description: `${response.message}`,
        });
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description:
          "Your Email Id not Found!. Please go back to the sign-up page",
      });
    }
  };

  const resendMobileOTPHandler = async () => {
    const userEmail = user.email;
    if (userEmail) {
      const response = await _POST("resendMobileOTP/" + userEmail, {}, false);
      if (response.status) {
        showSuccessCustomModal({
          title: "OTP Sent Successfully",
          smallText: "Your OTP sent to your Mobile Number for verification.",
          buttonText: "Continue",
        });
        setCounter(60);
      }
    } else {
      return notification.open({
        type: "error",
        message: "Something Went Wrong",
        description:
          "Your Email Id not Found!. Please go back to the sign-up page",
      });
    }
  };

  const onPhoneNumberChange = (name: any, value: any) => {
    setPhoneNumber((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <CustomLayout
      heading1={
        isGoogleSignup && !phoneNumberUpdated
          ? "Please Enter Your Phone Number to proceed"
          : "Verify Your Phone Number"
      }
      heading2={
        isGoogleSignup && !phoneNumberUpdated
          ? "You need to verify your phone number to proceed"
          : `Please enter the One-Time Password (OTP) sent to your registered mobile number to verify your identity.`
      }
    >
      <div className={Styles.verifyOtpContainer}>
        {isGoogleSignup && !phoneNumberUpdated ? (
          <Form
            size="large"
            className="digit-group verifyOtpContainer"
            onFinish={updatePhoneNumber}
          >
            <div className={Styles.verifyOtpContainer}>
              <Form.Item name="contactPersonPhone" label="">
                <Input.Group compact className={Styles.FormInput}>
                  <Select
                    defaultValue={phoneNumber.countryCode}
                    onChange={(value) =>
                      onPhoneNumberChange("countryCode", value)
                    }
                    showSearch
                    optionFilterProp="children"
                    className={Styles.FormInputNumber}
                  >
                    {countries.map((country) => (
                      <Option key={country.code} value={country.dial_code}>
                        <img
                          src={country.flag}
                          alt={country.name}
                          style={{ marginRight: 8, width: 20 }}
                        />
                        {country.dial_code}
                      </Option>
                    ))}
                  </Select>
                  <Input
                    onChange={(e) =>
                      onPhoneNumberChange("number", e.target.value)
                    }
                    placeholder="Enter phone number"
                    className={Styles.FormInputNumber}
                    defaultValue={phoneNumber.number}
                  />
                </Input.Group>
              </Form.Item>
            </div>
            <Button
              shape="round"
              type="primary"
              className={Styles.buttonBG}
              htmlType="submit"
            >
              Update
            </Button>
          </Form>
        ) : (
          <Form
            size="large"
            className={Styles.verifyOtpContainer}
            onFinish={verifyMobileHandler}
          >
            <div className={Styles.updatePhoneNumber}>
              {showUpdateModal ? null : (
                <span>If you entered the wrong number, </span>
              )}
              <Link onClick={() => setShowUpdateModal((value) => !value)}>
                {showUpdateModal ? "Not now" : "change number"}
              </Link>
              .
            </div>

            {showUpdateModal ? (
              <div className={Styles.verifyOtpContainer}>
                <Form.Item name="contactPersonPhone">
                  <Input.Group compact className={Styles.FormInput}>
                    <Select
                      defaultValue={phoneNumber.countryCode}
                      onChange={(value) =>
                        onPhoneNumberChange("countryCode", value)
                      }
                      showSearch
                      optionFilterProp="children"
                      className={Styles.FormInputNumber}
                    >
                      {countries.map((country) => (
                        <Option key={country.code} value={country.dial_code}>
                          <img
                            src={country.flag}
                            alt={country.name}
                            style={{ marginRight: 8, width: 20 }}
                          />
                          {country.dial_code}
                        </Option>
                      ))}
                    </Select>
                    <Input
                      onChange={(e) =>
                        onPhoneNumberChange("number", e.target.value)
                      }
                      placeholder="Enter phone number"
                      className={Styles.FormInputNumber}
                      defaultValue={phoneNumber.number}
                    />
                  </Input.Group>
                </Form.Item>
              </div>
            ) : (
              <Form.Item name="otp" className={Styles.otpContainer}>
                <div className={Styles.otpInputGroup}>
                  {[otp1, otp2, otp3, otp4, otp5, otp6].map((otp, index) => (
                    <div key={index} className={Styles.otpInputWrapper}>
                      <Input
                        type="text"
                        maxLength={1}
                        ref={
                          [
                            inputRef1,
                            inputRef2,
                            inputRef3,
                            inputRef4,
                            inputRef5,
                            inputRef6,
                          ][index]
                        }
                        className={Styles.otpInput}
                        value={otp}
                        onChange={(e) =>
                          inputChangeHandler(e.target.value, index)
                        }
                        onKeyDown={(e) => handleBackspaceNavigation(e, index)}
                        placeholder="0"
                      />
                    </div>
                  ))}
                </div>
              </Form.Item>
            )}

            <Button
              shape="round"
              type="primary"
              className={Styles.buttonBG}
              htmlType="button"
              onClick={
                showUpdateModal || (isGoogleSignup && !phoneNumberUpdated)
                  ? updatePhoneNumber
                  : verifyMobileHandler
              }
            >
              {showUpdateModal || (isGoogleSignup && !phoneNumberUpdated)
                ? "Update"
                : "Verify OTP"}
            </Button>

            {counter > 0 && (
              <span className={Styles.remainingTimeEmailVerify}>
                00:{counter < 10 ? `0${counter}` : counter}
              </span>
            )}

            {counter === 0 && !showUpdateModal && !isGoogleSignup && (
              <Button
                shape="round"
                type="link"
                htmlType="button"
                onClick={resendMobileOTPHandler}
              >
                Resend OTP
              </Button>
            )}
          </Form>
        )}
      </div>
    </CustomLayout>
  );
};

export default VerifyOtp;
