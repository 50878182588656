import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  styled,
  TooltipProps,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { Button, message, notification, Popconfirm, Typography } from "antd";
import { Visibility } from "@mui/icons-material";
import Styles from "../../OrderPages/freelancerComponets/Table.module.css";
import { _PATCH, _POST } from "../../../common/commonFunc";
import { CollabDetailsDrawer } from "./CollabDetailsDrawer";
import { useHistory } from "react-router";
import { ContractDetailsDrawer } from "./ContractDetailsDrawer";
import RejectContractModal from "./RejectContractModal";
import ReturnAcceptedProduct from "../../OrderPages/CustomerPage/ReturnAcceptedProduct";
import axios from "axios";

const ExpandedRowContractTable = ({ headers, data, fetchData }: any) => {
    console.log(" exp row data is : ", data)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [selectedDrawerService, setSelectedDrawerService] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [collabOpen, setCollabOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("");
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [rejectContactId, setRejectContactId] = useState("")
  const [rejectContactMode, setRejectContactMode] = useState("")
  const [returnOpen, setReturnOpen] = useState(false);
  const [qty, setQty] = useState(0)
  const [inventoryId, setInventoryId] = useState("")

  const history = useHistory();

  const [sortedData, setSortedData] = useState<any>([]);

  useEffect(() => {
    const sorted = [...data].sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
    setSortedData(sorted);
  }, [data]);

  useEffect(() => {
    console.log("sortedData is :", sortedData)
  },[sortedData])

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));

  const onOpen = (row: any) => {
    setOpen(true);
    setSelectedDrawerService(row);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedDrawerService([]);
  };

  const onCollabOpen = (row: any, type: string) => {
    setCollabOpen(true);
    setSelectedDrawerService(row);
    setDrawerType(type);
  };
  const onCollabClose = () => {
    setCollabOpen(false);
    setSelectedDrawerService([]);
    setDrawerType("");
  };

  const handleReturnProduct = (row : any) => {
    setReturnOpen(true)
    setQty(row.available_quantity)
    setInventoryId(row.assigned_inventory_id)
  }

  const handleMarkComplete = async (row: any) => {
    try {
      const completed = await _POST(`supplier/freelacner/completeContract?contractId=${row.contract_id}&contract_mode=${row.contract_mode}`, {}, true);
      if (completed.status) {
        notification.open({
          type: "success",
          message: "Contact Marked Completed",
          description: "This contract has been completed successfully.",
        });
        fetchData();
      } else {
        notification.open({
          type: "error",
          message: "Unable to Marke the contract complete",
          description: "This contract was not marked complete.",
        });
      }
    } catch (error) {
      console.error(error);
    }


  }

  const handleAcceptProduct = async (contract_id: string) => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/upload-sign-contract/${contract_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Product Collaboration Signed !!",
            description: "Your Collaboration has been signed Successfully",
          });
          fetchData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Created !!",
            description: "Your Collaboration was not created ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleAcceptContract = async (contractId: string) => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/freelancer/signNonProductContract/${contractId}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Contract Signed!",
            description: "Your contract has been signed successfully",
          });
          fetchData();
        } else {
          notification.open({
            type: "error",
            message: "Contract Not signed !!",
            description: "Your contract was not signed ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRejectContract = async (contract_id: string, contract_mode: string) => {
    setOpenRejectModal(true)
    setRejectContactId(contract_id)
    setRejectContactMode(contract_mode)
  }

  const closeRejectContractModal = () => {
    setOpenRejectModal(false)
    setRejectContactId("")
    setRejectContactMode("")
  }

  const renderPaymentStatus = (advance_paid : boolean, contract_type : any, contract_status : string) => {
    if(contract_status === "rejected" || contract_status === "pending"){
        return (
            <Typography>
                -
            </Typography>)
    }
    if(contract_type === "commission_percentage") {
        return (
        <Typography>
            -
        </Typography>)
    }else {
        if(advance_paid){
            return (
                <Typography style={{color:"green"}}>
                    Advance Paid
                </Typography>)
        }else {
            return (
                <Typography style={{color:"red"}}>
                    Advance Pending
                </Typography>)
        }
    }
  }

  const handleTerminateContract = async (row : any) => {
    // console.log("row is : ", row)
     try {
       const res = await axios.patch(
         `https://testing.theoctopus.ai:8443/supplier/terminateContract?contract_id=${row?.contract_id}&contract_mode=${row.contract_mode}`,
        {},
        { headers: {
          "x-access-token":
            localStorage.getItem("jwtToken") ||
            localStorage.getItem("Temp_jwtToken"),
        }}
       );
       
       if(res.status){
        fetchData()
      }
     } catch (error) {
       console.error("Error fetching product data:", error);
       return {};
     }
   }

  const renderActionButtons = (row: any, status: any) => {
    console.log("checking row inside render action button", row);
    switch (status) {
      case "non_product_based_accepted":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {row.is_advance_amount_paid && (
              <Popconfirm
                title="Are you sure you want to mark this contract complete?"
                onConfirm={() => { handleMarkComplete(row) }}
                okText="Yes"
                cancelText="No"
              >
                {!row.is_balance_amount_paid && <Button className={Styles.reschedule}>
                  Mark Contract Complete
                </Button>}
              </Popconfirm>
            )}
             {!row.is_advance_amount_paid && <Popconfirm
                  title="Are you sure you want to terminate this contract?"
                  onConfirm={() => { handleTerminateContract(row) }}
                  okText="Yes"
                  cancelText="No"
                >
                   <Button className={Styles.reschedule}>
                    Terminate
                  </Button>
                </Popconfirm>}
            <Button className={Styles.eye} onClick={() => onOpen(row)}>
              <Visibility />
            </Button>
          </div>
        );
      case "product_based_accepted":
        return (
          <div className={Styles.actionButtons}>
            <Button className={Styles.cancel} onClick={() => {handleReturnProduct(row)}}>Return Product</Button>
            <Popconfirm
                  title="Are you sure you want to terminate this contract?"
                  onConfirm={() => { handleTerminateContract(row) }}
                  okText="Yes"
                  cancelText="No"
                >
                  {!row.is_balance_amount_paid && <Button className={Styles.reschedule}>
                    Terminate
                  </Button>}
                </Popconfirm>
            <Button
              className={Styles.eye}
              onClick={() => onCollabOpen(row, "prodDetails_exp")}
            >
              <Visibility />
            </Button>
          </div>
        );
      case "non_product_based_pending":
        return (
          <div className={Styles.actionButtons}>
            <Popconfirm
              title="Are you sure you want to accept this contract?"
              onConfirm={() => handleAcceptContract(row?.contract_id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className={Styles.reschedule}>Accept</Button>
            </Popconfirm>
            <Button className={Styles.cancel}
              onClick={() => handleRejectContract(row?.contract_id, row?.contract_mode)}>
              Reject</Button>

            <Button className={Styles.eye} onClick={() => onOpen(row)}>
              <Visibility />
            </Button>
          </div>
        );
      case "product_based_pending":
        return (
          <div className={Styles.actionButtons}>
            <Popconfirm
              title="Are you sure you want to accept this product?"
              onConfirm={() => handleAcceptProduct(row?.contract_id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className={Styles.reschedule}>Accept</Button>
            </Popconfirm>
            <Button className={Styles.cancel}
              onClick={() => handleRejectContract(row?.contract_id, row?.contract_mode)}>
              Reject</Button>

            <Button
              className={Styles.eye}
              onClick={() => onCollabOpen(row, "prodDetails_exp")}
            >
              <Visibility />
            </Button>
          </div>
        );
        case "non_product_based_rejected" : return ("-")
        case "product_based_rejected" : return ("-")
      default:
        return null;
    }
  };

  function getPlainText(text: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    return doc.body.textContent || "";
  }

 
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const renderId = (id: string) => {
    return (
      <LightTooltip
        title={
          <>
            <div style={{ fontWeight: "bold" }}>Click to Copy :</div>
            <br />
            <div>{id}</div>
          </>
        }
        placement="bottom"
        arrow
      >
        <span
          onClick={(event) => copyToClipboard(id, event)}
          style={{
            cursor: "pointer",
          }}
        >
          {formatId(id)}
        </span>
      </LightTooltip>
    );
  };

  const renderDesc = (text: string) => {
    return (
      <LightTooltip
        title={<>{getPlainText(text ?? " ")}</>}
        placement="bottom"
        arrow
      >
        <div>{text ? formatDesc(getPlainText(text ?? " ")) : "-"}</div>
      </LightTooltip>
    );
  };


  const formatDesc = (desc: any) => {
    const prefix = desc?.substring(0, 20);
    if (desc?.length > 20) {
      return `${prefix}${" ... "}`;
    }
    return `${prefix}`;
  };

  const formatId = (id: any) => {
    const prefix = id?.substring(0, 7);
    const start = id?.substring(8, 10);
    const end = id?.substring(id.length - 2);
    return `${prefix}${" : "}${start}..${end}`;
  };

  const copyToClipboard = async (text: any, event: any) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      notification.success({ message: "Request ID copied to clipboard" });
    } catch (err) {
      notification.error({ message: "Failed to copy Request ID" });
    }
    event.stopPropagation();
  };

  return (
    <>
      <TableContainer component={Paper} className={Styles.tableContainer}>
        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableHead className={Styles.tableHeader}>
              <TableRow>
                {headers.map((header: any) => (
                  <TableCell
                    key={header.id}
                    style={{
                      width: header.width,
                      textAlign: "center",
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </div>

        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableBody className={Styles.tableBody}>
              {sortedData?.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    style={{ textAlign: "center" }}
                  >
                    <h1> No data available</h1>
                  </TableCell>
                </TableRow>
              ) : (
                sortedData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row: any, index: any) => (
                    <TableRow key={index}>
                      {headers.map((header: any) => (
                        <TableCell
                          key={`${header.id}-${index}`}
                          style={{
                            width: header.width,
                            textAlign: "center",
                          }}
                        >
                          {header.id === "contract_id" ? (
                            renderId(row?.contract_id)
                          )  : header.id === "start_date" ? (
                            formatDate(row?.collaboration_start_date)
                          ) : header.id === "end_date" ? (
                            formatDate(row?.collaboration_end_date)
                          ) :header.id === "contract_text" ? (
                            renderDesc(row?.contract_text)
                          ):header.id === "contract_mode" ? (
                            row?.contract_mode === "product_based" ? "Product Based" : "Non Product Based"
                          ) :header.id === "contract_status" ? (
                            <p style={{color:row?.contract_status === "pending" ? "#1677ff" : 
                                row?.contract_status === "accepted" ? "limegreen": 
                                "red"}}>{row?.contract_status?.toUpperCase()}</p>
                          ) :header.id === "contract_type" ? (
                            row?.contract_type && row?.contract_type === "commission_percentage" ? "Commission Percentage" : "Fixed Amount" 
                          ) : header.id === "fixed_amount" ? (
                            row?.contract_type !== "commission_percentage"? <>
                              <Typography>
                                <b>BHD</b> {row?.fixed_amount}
                              </Typography>{" "}
                            </> : "-"
                          ) : header.id === "payment_status" ? (
                           renderPaymentStatus(row?.is_advance_amount_paid, row?.contract_type, row?.contract_status)
                          )  :  header.id === "action" ? (
                            renderActionButtons(row, row?.contract_mode+"_"+row?.contract_status)
                          ) : (
                            row[header.id]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <div className={Styles.tableFooter}>
            <div className={Styles.viewControls}>
         {" "}
            </div>
            <TablePagination
              component="div"
              count={sortedData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[2, 5, 10, 25, 50, 100, 200]}
              className={Styles.pagination}
            />
          </div>
        </div>
      </TableContainer>

      <ContractDetailsDrawer
        onOpen={open}
        onClose={onClose}
        contractDetails={selectedDrawerService}
        fetchData={fetchData}
      />

      <CollabDetailsDrawer
        open={collabOpen}
        onClose={onCollabClose}
        collabDetails={selectedDrawerService}
        fetchData={fetchData}
        type={drawerType}
      />

      <RejectContractModal
        isVisible={openRejectModal}
        onClose={closeRejectContractModal}
        contract_id={rejectContactId}
        contract_mode={rejectContactMode}
        fetchData={fetchData}
      />
       <ReturnAcceptedProduct
                isVisible={returnOpen}
                onClose={() => setReturnOpen(false)}
                id={inventoryId}
                quantity={qty}
            />
    </>
  );
};

export default ExpandedRowContractTable;
