import { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Layout,
  Menu,
  Drawer,
  Space,
  Dropdown,
  theme,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import Cross from "../../../assests/customIcons/Cross";
import FilerIcon from "../../../assests/Filter.svg";
import SearchIcon from "../../../assests/searchiconnew.svg";
import Sidebar from "../Sidebar/Sidebar";
import Styles from "./Productlist.module.css";
import CustomProductListTable from "./CustomProductListTable";
import { _GET } from "../../../common/commonFunc";
import { STORE } from "../../../redux/store";
import Notification from "../../../assests/customIcons/Notification";
import CSVIcon from "../../../assests/CSV.png";
import axios from "axios";
import { Box, Typography } from "@mui/material";

const { Header, Sider, Content } = Layout;

const ProductList = () => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const UserDetailsLS: any = localStorage.getItem("userInfo");
  const UserDetails = JSON.parse(UserDetailsLS);
  const [avatarColor, setAvatarColor] = useState("");
  const [selectedKey, setSelectedKey] = useState("2");
  const [productData, setProductData] = useState("");

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const updateSelectedKey = (key: any) => {
    setSelectedKey(key);
  };

  const redirectlinkClick = (route: any) => {
    setSelectedKey("Profile");
    history.push(route);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const history = useHistory<any>();
  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  useEffect(() => {
    let color = localStorage.getItem("avatarColor");
    if (!color) {
      color = getRandomColor();
      localStorage.setItem("avatarColor", color);
    }
    setAvatarColor(color);
  }, []);

  const renderAvatar = () => {
    if (UserDetails.profile_pic && UserDetails.profile_pic !== "NULL") {
      return (
        <img
          src={UserDetails.profile_pic}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
          alt="avatar"
        />
      );
    } else {
      const initial = UserDetails.first_name ? UserDetails.first_name[0] : "";
      const last = UserDetails.last_name ? UserDetails.last_name[0] : "";
      const comp = initial + last;
      const bgColor = getRandomColor();
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: avatarColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {comp.toUpperCase()}
        </div>
      );
    }
  };

  const getProductData = async () => {
    try {
      const res = await axios.get(
        "https://testing.theoctopus.ai:8443/supplier/product/fetch/products?limit=10&offset=0"
      );
      return res.data.data;
    } catch (error) {
      console.error("Error fetching product data:", error);
      return [];
    }
  };

  useEffect(() => {
    getProductData().then((data) => {
      setProductData(data);
      console.log("data is ========> ", data);
      console.log("Product data is =====> ", productData);
    });
  }, []);

  const handleLogout = () => {
    STORE.dispatch({ type: "LOGOUT_USER" });
    window.location.href = APPLICATION_ROUTES.LOGIN;
  };

  const headers = [
    { id: "sku_id", label: "SKU ID", width: "10%" },
    { id: "product_name", label: "PRODUCT NAME", width: "15%" },
    { id: "category", label: "CATEGORY", width: "10%" },
    { id: "stock_availability", label: "STOCK AVAILABILITY", width: "10%" },
    { id: "total_orders", label: "TOTAL ORDERS", width: "8%" },
    { id: "price_bhd", label: "PRICE BHD (PER UNIT)", width: "10%" },
    { id: "discount", label: "DISCOUNT (%)", width: "8%" },
    { id: "brand", label: "BRAND", width: "8%" },
    { id: "no_of_collaborator", label: "NO. OF COLLABORATOR", width: "5%" },
    { id: "status", label: "STATUS", width: "8%" },
    { id: "action", label: "ACTION", width: "8%" },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="0">Account</Menu.Item>
      <Menu.Item key="1">Settings</Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={handleLogout} key="3">
        LogOut
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA !important",
        background: "#F5F4FA !important",
      }}
      className={Styles.customLayoutBackground}
    >
      <Sidebar />
      <Layout className={Styles.customLayoutBackground}>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            margin: "10px 20px",
            borderRadius: borderRadiusLG,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 10,
                marginLeft: 10,
              }}
            >
              <img src={SearchIcon} alt="Search" style={{ marginRight: 5 }} />
              <input
                type="text"
                placeholder="What you want to search ..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{
                  width: 200,
                  border: "none",
                  boxShadow: "none",
                  outline: "none",
                  backgroundColor: "none",
                }}
              />
            </div>
          </div>
          <div className={Styles.iconsContainer}>
            <button
              onClick={() => {}}
              className="elevatedElement iconBtn"
              style={{ position: "relative" }}
            >
              <Notification />
              <div className={Styles.countOnIcon}>4</div>
            </button>

            <button
              className="elevatedElement iconBtn"
              style={{
                padding: 5,
                cursor: "pointer",
                marginLeft: "10px",
              }}
              onClick={() => {
                redirectlinkClick("profile");
              }}
            >
              {renderAvatar()}
            </button>

            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                className={Styles.userMenu}
                type="text"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </div>
        </Header>
        <Content
          className={Styles.hiddenScrollbar}
          style={{
            margin: "0px 20px",
            paddingTop: 0,
            minHeight: 280,
            borderRadius: borderRadiusLG,
            overflow: "auto",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: "34px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <h3 className={Styles.myStoreText}>My Store</h3>
              <Typography className={Styles.skuCountText}>
                {productData.length}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box>
                <div className={Styles.topSearchDiv}>
                  <img
                    className={Styles.SearchIcon}
                    src={SearchIcon}
                    alt="Search"
                    style={{ marginRight: 5 }}
                  />
                  <input
                    type="text"
                    placeholder="Search..."
                    className={Styles.searchInput}
                    // value={searchInput}
                    // onChange={handleSearchInputChange}
                  />
                  <div className={Styles.filterIcon}>
                    <img
                      src={FilerIcon}
                      alt="Search"
                      style={{ marginRight: 5 }}
                    />
                  </div>
                </div>
              </Box>
              <Button onClick={showDrawer} className={Styles.addButton}>
                Add New Product
              </Button>
              <Button className={Styles.getcsvbutton}>
                <img src={CSVIcon} alt="csv" /> Get CSV
              </Button>
            </Box>
          </Box>

          <Drawer
            title="Add New Product"
            placement="right"
            onClose={onClose}
            open={open}
            key="right"
            width={"550px"}
            closable={false}
            extra={
              <Space style={{ zIndex: 100 }}>
                <Button onClick={onClose} className="elevatedElement iconBtn">
                  <Cross />
                </Button>
              </Space>
            }
          ></Drawer>

          <CustomProductListTable headers={headers} data={productData} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default ProductList;
