import React from "react";
import axios, { AxiosRequestHeaders } from "axios";
import { notification } from "antd";
import _ from "lodash";
import { LocalActivity } from "@mui/icons-material";
import { APPLICATION_ROUTES, BASE_URL } from "../constant/ApplicationRoutes";
import { STORE } from "../redux/store";
import {
  getCookie,
  getValidAccessToken,
  logoutUser,
  refreshAccessToken,
} from "../utils/tokenService";
import moment from "moment";

export const zoho_user_id: any = localStorage.getItem("zohoUserID");
export const getZohoUserId = () => {
  return localStorage.getItem("zohoUserID");
};

// export const _POST = async (
//   url: string,
//   body: any,
//   isHeaderUsed: boolean,
//   showError: boolean = true
// ) => {
//   const baseURL = "https://testing.theoctopus.ai:8443/";

//   const makeRequest = async (token: any) => {
//     const headers =
//       isHeaderUsed && token
//         ? {
//             "x-access-token": token,
//             "ngrok-skip-browser-warning": "1",
//           }
//         : {};

//     return axios.post(baseURL + url, body, { headers });
//   };

//   try {
//     let token = localStorage.getItem("Temp_jwtToken") || null;
//     const jwtToken = localStorage.getItem("jwtToken");

//     if (jwtToken) {
//       try {
//         token = await refreshAccessToken();
//       } catch (refreshError) {
//         console.error("Failed to refresh token:", refreshError);
//         throw refreshError;
//       }
//     }

//     const res = await makeRequest(token);
//     return _.get(res, "data", []);
//   } catch (error: any) {
//     if (showError && (!error.response || error.response.status !== 500)) {
//       notification.open({
//         type: "error",
//         message: "Something Went Wrong",
//         description: _.get(error, "response.data.message", ""),
//       });
//     }

//     throw error;
//   }
// };

export const _POST = async (
  url: string,
  body: any,
  isHeaderUsed: boolean,
  showError: boolean = true
) => {
  const baseURL = "https://testing.theoctopus.ai:8443/";
  // const refreshToken: any = getCookie("refreshToken");
  const headers: Record<string, string> = {
    "ngrok-skip-browser-warning": "1",
    // "x-refresh-toke": refreshToken,
  };
  https: if (isHeaderUsed) {
    try {
      let token;
      if (localStorage.getItem("jwtToken")) {
        token = await getValidAccessToken();
        headers["x-access-token"] = token;
        console.log("➡️   ~ token:", token);
      } else {
        token = localStorage.getItem("Temp_jwtToken") || null;
      }
      const res = await axios.post(baseURL + url, body, {
        headers: {
          "x-access-token": token || localStorage.getItem("Temp_jwtToken"),
          "ngrok-skip-browser-warning": "1",
        },
      });
      return _.get(res, "data", []);
    } catch (error: any) {
      console.log("Trying post");

      if (error.response.status === 401 || error.response.status === 403) {
        await localStorage.removeItem("jwtToken");
        const token = await refreshAccessToken();

        const res = await axios.post(baseURL + url, body, {
          headers: {
            "x-access-token": token || localStorage.getItem("Temp_jwtToken"),
            "ngrok-skip-browser-warning": "1",
          },
        });
        return _.get(res, "data", []);
      }

      if (showError && (!error.response || error.response.status !== 500)) {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }

      return _.get(error, "response.data", "");
    }
  } else {
    try {
      const res = await axios.post(BASE_URL + url, body, {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
        },
      });
      return _.get(res, "data", []);
    } catch (error: any) {
      if (showError && (!error.response || error.response.status !== 500)) {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }

      return _.get(error, "response.data", "");
    }
  }
};

export const _PATCH = async (url: string, body: any, isHeaderUsed: boolean) => {
  // const baseURL = "https://c8bf-122-177-51-82.ngrok-free.app/";
  // const baseURL = " https://testing.theoctopus.ai:8443/";
  // const baseURL = "http://15.184.217.36/";
  // const baseURL = "http://localhost:5000/";
  // const baseURL = "https://789c-122-177-51-82.ngrok-free.app/";
  const baseURL = " https://testing.theoctopus.ai:8443/";
  if (isHeaderUsed) {
    try {
      const res = await axios.patch(BASE_URL + url, body, {
        headers: {
          "x-access-token":
            localStorage.getItem("jwtToken") ||
            localStorage.getItem("Temp_jwtToken"),
        },
      });
      return _.get(res, "data", []);
    } catch (error: any) {
      if (!error.response || error.response.status !== 500) {
        console.log("error", error);
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }
      return _.get(error, "response.data", "");
    }
  } else {
    try {
      const res = await axios.patch(BASE_URL + url, body, {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          "ngrok-skip-browser-warning": "1",
        },
      });
      return _.get(res, "data", []);
    } catch (error: any) {
      console.log("error", error);
      // alert(error);
      if (!error.response || error.response.status !== 500) {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }
      return _.get(error, "response.data", "");
    }
  }
};

export const _GET = async (
  url: string,
  isHeaderUsed: boolean,
  showError: boolean = true
) => {
  const baseURL = "https://testing.theoctopus.ai:8443/";

  try {
    const refreshToken: any = getCookie("refreshToken");
    const headers: Record<string, string> = {
      "ngrok-skip-browser-warning": "1",
      // "x-refresh-token": refreshToken,
    };

    const role = localStorage.getItem("octo_role");
    const skipToken = role === "guest";

    if (isHeaderUsed && !skipToken) {
      const token = await getValidAccessToken();
      if (!token) throw new Error("Token not available");

      headers["x-access-token"] = token;
    }

    const res = await axios.get(baseURL + url, { headers });
    return _.get(res, "data", []);
  } catch (error: any) {
    if (showError) {
      notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: _.get(error, "response.data.message", ""),
      });
    }

    const role = localStorage.getItem("octo_role");
    if (
      error.response?.status === 403 ||
      (error.response?.status === 401 && isHeaderUsed && role !== "guest")
    ) {
      try {
        await localStorage.removeItem("jwtToken");
        const newToken = await refreshAccessToken();
        if (!newToken) throw new Error("Failed to refresh token");

        const retryHeaders: Record<string, string> = {
          "ngrok-skip-browser-warning": "1",
          "x-access-token": newToken,
        };

        const res = await axios.get(baseURL + url, { headers: retryHeaders });

        return _.get(res, "data", []);
      } catch (refreshError) {
        console.error("Failed to refresh token:", refreshError);
        return;
      }
    }

    if (showError && (!error.response || error.response.status !== 500)) {
      notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: _.get(error, "response.data.message", ""),
      });
    }

    throw error;
  }
};

export const _DELETE = async (
  url: string,
  isHeaderUsed: boolean,
  showError: boolean = true
) => {
  const baseURL = " https://testing.theoctopus.ai:8443/";

  if (isHeaderUsed) {
    try {
      const res = await axios.delete(BASE_URL + url, {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
        },
      });
      return _.get(res, "data", []);
    } catch (error: any) {
      if (showError && (!error.response || error.response.status !== 500)) {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }
    }
  } else {
    try {
      const res = await axios.delete(BASE_URL + url);
      return _.get(res, "data", []);
    } catch (error: any) {
      if (showError && (!error.response || error.response.status !== 500)) {
        notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: _.get(error, "response.data.message", ""),
        });
      }
    }
  }
};

export const getRole = () => {
  const role = localStorage.getItem("octo_role");
  if (!role) {
    return "guest";
  }
  return role;
};

export const getUserType = () => {
  const userType: any = localStorage.getItem("userInfo");
  const type = JSON.parse(userType)?.userType;
  return type;
};

export const agreed_to_terms_and_condition = () => {
  const agreed_to_terms_and_condition: any = localStorage.getItem("userInfo");
  const agree = JSON.parse(
    agreed_to_terms_and_condition
  )?.agreed_to_terms_and_condition;
  return agree;
};

export const isDocUpdated = () => {
  const isDocUpdated: any = localStorage.getItem("userInfo");
  const updated = JSON.parse(isDocUpdated)?.is_document_updated;
  return updated;
};

export const regType = () => {
  const regType: any = localStorage.getItem("userInfo");
  const type = JSON.parse(regType)?.register_type;
  return type;
};

export const getUserId = () => {
  const userInfo: any = localStorage.getItem("userInfo");
  const userId = JSON.parse(userInfo)?.id;
  return userId;
};

export const getSupplierID = () => {
  const userInfo: any = localStorage.getItem("userInfo");
  const userId = JSON.parse(userInfo).supplier_id;
  return userId;
};

// export default {_POST, _GET};

export const _MGET = async (
  url: string,
  params: {
    buffer_time: number;
    serviceTimeDuration: number;
    date: string;
    serviceId: string;
    providerId: string;
    start_time: number;
  },
  isHeaderUsed: boolean
) => {
  const baseURL = "http://localhost:5000/";

  if (isHeaderUsed) {
    try {
      const res = await axios.get(BASE_URL + url, {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
        params: params,
      });
      return _.get(res, "data", []);
    } catch (error) {
      notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: _.get(error, "response.data.message", ""),
      });
    }
  } else {
    try {
      const res = await axios.get(BASE_URL + url, {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
        params: params,
      });
      return _.get(res, "data", []);
    } catch (error) {
      notification.open({
        type: "error",
        message: "Something Went Wrong",
        description: _.get(error, "response.data.message", ""),
      });
    }
  }
};

export function calculateCommentDateAge(dateString: any) {
  const date = moment(dateString);
  const now = moment();
  const duration = moment.duration(now.diff(date));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let result = "";

  if (years > 0) {
    result += years + (years === 1 ? " Year" : " Years") + " ";
    return result;
  }
  if (months > 0) {
    result += months + (months === 1 ? " Month" : " Months") + " ";
    return result;
  }
  if (days > 0) {
    result += days + (days === 1 ? " Day" : " Days") + " ";
    return result;
  }
  if (hours > 0) {
    result += hours + (hours === 1 ? " Hour" : " Hours") + " ";
    return result;
  }
  if (minutes > 0) {
    result += minutes + (minutes === 1 ? " Minute" : " Minutes") + " ";
    return result;
  }
  if (seconds > 0) {
    result += seconds + (seconds === 1 ? " Second" : " Seconds") + " ";
    return result;
  }

  return result.trim();
}
