import { useState, useEffect, useMemo } from "react";
import {
  Breadcrumb,
  Button,
  Layout,
  Menu,
  Drawer,
  Space,
  theme,
  Form,
  Row,
  Col,
  ColorPicker,
  Input,
  Cascader,
  InputNumber,
  notification,
  Popconfirm,
  Radio,
} from "antd";
import { useHistory, useParams } from "react-router";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import Cross from "../../../assests/customIcons/Cross";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import Styles from "./Productdetails.module.css";
import { _DELETE, _GET, _POST } from "../../../common/commonFunc";
import { STORE } from "../../../redux/store";
import { Box, Divider, Grid, Icon, IconButton, Skeleton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Delete from "../../../assests/customIcons/Delete";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import CustomImageGallery from "./CustomImageGallery";
import { Reply, Star } from "@mui/icons-material";
import axios from "axios";
import ProductDataGrid from "./ProductDataGrid";
import CustomIconButton from "./CustomIconButton";
import ProductCollaborationGrid from "./ProductCollaborationsGrid";
import Paper from "@mui/material/Paper";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { RWebShare } from "react-web-share";
import ProductDetailsTable from "./ProdDetailsTable";
import FileUpload from "../../../components/InputComponents/FileUpload";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import RichTextEditor from "../../../components/RichText/Quill2";
import EditProductDetails from "./EditProductDetails";

const { Header, Content } = Layout;

const OctopusSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundImage:
          "linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%);",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const LightTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#30263E",
    boxShadow: "0px 8px 24px 0px #422F9B1A",
    fontSize: 12,
    maxWidth: "271.44px",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  paddingTop: "30px",
  paddingLeft: "30px",
}));

const ItemGallery = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
}));

const ProductDetails = (ids: any) => {
  console.log("🚀 ~ ProductDetails ~ id:", ids);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const UserDetailsLS: any = localStorage.getItem("userInfo");
  const UserDetails = JSON.parse(UserDetailsLS);
  const [avatarColor, setAvatarColor] = useState("");
  const [selectedKey, setSelectedKey] = useState("2");
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [service, setService] = useState<any>([]);
  const [count, setCount] = useState(1);
  const [editorContent1, setEditorContent1] = useState<any>(null);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [productDetails, setProductDetails] = useState<any>({});
  // console.log("🚀 ~ ProductDetails ~ productDetails:", productDetails);
  const [image, setImage] = useState([]);
  const { id } = useParams<{ id: string }>();
  const [drawerForm] = Form.useForm();
  const [specsImages, setSpecsImages] = useState<any>([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [isLongDescription, setIsLongDescription] = useState(false);
  const [prodImages, setProdImages] = useState<any>([]);
  const [attributes, setAttributes] = useState<any>({});
  const [attributeId, setAttributeId] = useState<any>();
  const [colorHex, setColorHex] = useState<any>("#1677ff");
  const [formatHex, setFormatHex] = useState<any>("hex");
  const [zohoId, setZohoId] = useState();
  const [visibleEditDrawer, setVisibleEditDrawer] = useState(false);
  const [editData, setEditData] = useState<any>({});
 

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const currentPageUrl = window.location.href;

  const redirectlinkClick = (route: any) => {
    setSelectedKey("Profile");
    history.push(route);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    drawerForm.resetFields();
    setSpecsImages([]);
    setColorHex("#1677ff");
    setImagePreviews([]);
  };

  const openEditProductDrawer = async (item: any) => {
    if (item) {
      await setVisibleEditDrawer(true);
      await setEditData(item);
    }
  };

  const closeEditProductDrawer = () => {
    setVisibleEditDrawer(false);
    getProductDetails();
  };

  const history = useHistory<any>();
  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const hexString = useMemo<string>(
    () => (typeof colorHex === "string" ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );

  const onColorChange = (color: any) => {
    setColorHex(color);
    drawerForm.setFieldsValue({ hexValue: color.toHexString() ?? "#fff" });
  };

  const updateServiceImages = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setSpecsImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = specsImages;
      updatedImages.splice(index, 1);
      setSpecsImages(updatedImages);
    }
  };



  const handleEditorChange1 = (content: any) => {
    setEditorContent1(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
  };

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const updateServiceImagesMain = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setProdImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = prodImages;
      updatedImages.splice(index, 1);
      setProdImages(updatedImages);
    }
  };

  const shareText = `Checkout this new Product:\n Product Name: ${productDetails?.product_name} Link:\n`;

  useEffect(() => {
    let color = localStorage.getItem("avatarColor");
    if (!color) {
      color = getRandomColor();
      localStorage.setItem("avatarColor", color);
    }
    setAvatarColor(color);
  }, []);

  const formatProductId = (id: any) => {
    const prefix = id.substring(0, 2);
    const start = id.substring(8, 10);
    const end = id.substring(id.length - 2);
    return `${prefix}${" : "}${start}..${end}`;
  };

  const handleClickRow = (imageArray: any) => {
    // console.log("image array has value : ", imageArray);
    setImage(imageArray);
  };

  const getProductDetails = async () => {
    try {
      const res = await axios.get(
        `https://testing.theoctopus.ai:8443/supplier/product/fetch/product?product_id=${id}`
      );
      console.log("response is : ", res);
      setProductDetails(res.data.data[0]);
      setImage(res.data.data[0].product_images);
      return res.data.data[0];
    } catch (error) {
      console.error("Error fetching product data:", error);
      return {};
    }
  };

  useEffect(() => {
    getProductDetails().then((data: any) => {
      console.log("data is ========> ", data);

      const desc = countWords(data.product_description) > 100;
      setIsLongDescription(desc);
      getProductAttributes(data.prod_category_id);
      getSupplierZohoId(data.supplier_zoho_id);
    });
  }, []);

  const getSupplierZohoId = (zoho_id: any) => {
    setZohoId(zoho_id);
  };

  const discountedPrice =
    productDetails?.product_unit_price *
    (1 - productDetails.prod_discount / 100);

  const handleLogout = () => {
    STORE.dispatch({ type: "LOGOUT_USER" });
    window.location.href = APPLICATION_ROUTES.LOGIN;
  };

  const countWords = (str: string) => {
    return str?.split(/\s+/).length;
  };

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((ele: any) => {
        if (ele === "images") {
          data[ele].forEach((image: any) => {
            formData.append("images", image);
          });
        } else {
          formData.append(ele, data[ele]);
        }
      });
    }
    return formData;
  };

  const getProductAttributes = async (category_id: any) => {
    try {
      const res = await _GET(
        `supplier/product/fetch/attributes?category_id=${category_id}`,
        false,
        false
      );

      if (res.statusCode === 200) {
        setAttributes(res.data.attribute_features);
      }
    } catch (error) {
      console.error("error:", error);
      notification.error({
        message: "Failed to fetch attributes",
      });
    }
  };

  const handleDelete = async (prodId: any) => {
    try {
      const url = `supplier/product/delete/product/${prodId}`;
      const response = await _DELETE(url, true);

      if (response.status && response.statusCode === 200) {
        history.push("/supplier/mystore");
        notification.success({
          message: "Product Deleted",
          description: "The product has been successfully deleted.",
        });
      } else {
        notification.success({
          message: "Error ",
          description: "There was a problem in deleting the product.",
        });
      }
    } catch (error) {
      console.error("Error in deleting item:", error);
    }
  };

  const getUpdatedImagesMain = async () => {
    if (prodImages.length > 0) {
      const allValues = {
        images: prodImages,
      };

      const mainValues = await convertDataToFormData(allValues);

      const response = await _POST(
        "services/uploadServiceImagesToS3",
        mainValues,
        true,
        false
      );
      if (response) {
        if (response.data?.length > 0) {
          return response.data;
        } else {
          notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please try again to upload your images",
          });
        }
      }
    } else {
      return [];
    }
  };

  const onFinish = async (values: any) => {
    const uploadedImages = await getUpdatedImagesMain();
    const payload = {
      color: values.color,
      size: values.size,
      hexValue: hexString,
      prod_scale: values.system,
      quantity: values.quantity,
      images: uploadedImages,
      supplier_zoho_id: zohoId,
    };

    const response = await _POST(`supplier/addInevntory/${id}`, payload, true);
    if (response) {
      if (response.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        onClose();
        getProductDetails();
      } else {
        notification.error({
          message: response.message,
        });
      }
    }
  };

  // const sizesBySystem =
  //   attributes?.sizes?.allSizes?.reduce((acc: any, size: any) => {
  //     const system = size.system ?? "Size Category";
  //     if (!acc[system] || acc[system]?.length === 0) {
  //       acc[system] = [];
  //     }

  //     if (!acc[system].find((s: any) => s.size === size.size))
  //       acc[system].push(size);

  //     return acc;
  //   }, {}) ?? {};

  const menu = Object.keys(attributes?.sizes ?? {}).map((system) => ({
    value: system,
    label: system,
    children: attributes.sizes[system].map((size: any) => ({
      value: size.size,
      label: `${size.size} - ${size.description}`,
    })),
  }));

  menu.push({
    value: "NA",
    label: "Not Applicable",
    children: [],
  });
  // console.log("size by system is ", sizesBySystem);
  console.log("menu is ", menu);

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA !important",
        background: "#F5F4FA !important",
      }}
      className={Styles.customLayoutBackground}
    >
      <Layout className={Styles.customLayoutBackground}>
        <Content
          className={Styles.hiddenScrollbar}
          style={{
            margin: "0px 20px",
            paddingTop: 0,
            minHeight: 280,
            borderRadius: borderRadiusLG,
            overflow: "auto",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: "34px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <div className={Styles.headingDiv}>
                {" "}
                <span className={Styles.myStoreText}>
                  ID |
                  <LightTooltip title={id} placement="right" arrow>
                    <span>{formatProductId(id)}</span>
                  </LightTooltip>
                </span>
                <Breadcrumb
                  className={Styles.breadcrumStyle}
                  separator={<img src={breadcrumbIcon} alt=">" />}
                >
                  <Breadcrumb.Item
                    className={Styles.breadcrumbItem}
                    onClick={() => handleBreadcrumbClick("/supplier/mystore")}
                  >
                    My Store
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>View detail</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box>
                <span className={Styles.timelineStyle}>
                  {/* Posted on Mar 1, 2024 | Last modified on Mar 4, 2024{" "} */}
                </span>
              </Box>
              <div className={Styles.statusToggle}>
                Status
                <OctopusSwitch className={Styles.toggleSwitch} defaultChecked />
              </div>
              <IconButton
                className={Styles.iconButton}
                onClick={() => openEditProductDrawer(productDetails)}
              >
                <EditOutlinedIcon className={Styles.iconStyle} />
              </IconButton>
              <Popconfirm
                title="Are you sure you want to Delete the product?"
                onConfirm={() => handleDelete(id)}
                okText="Yes"
                cancelText="No"
              >
                <IconButton className={Styles.iconButton}>
                  <Delete />
                </IconButton>
              </Popconfirm>
              <RWebShare
                data={{
                  text: shareText,
                  url: currentPageUrl,
                  title: service?.service_name,
                }}
              >
                <IconButton className={Styles.iconButtonArrow}>
                  <Reply style={{ color: "#30263E" }} />
                </IconButton>
              </RWebShare>
            </Box>
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={5}>
              <ItemGallery>
                <CustomImageGallery images={image} />
              </ItemGallery>
              <div className={Styles.tryOnFilter}>
                <a className={Styles.tryOnFilterAnchor}>
                  REQUEST TRY ON FILTER
                </a>
              </div>
            </Grid>

            <Grid item xs={7}>
              <Item>
                <div className={Styles.brandStyle}>
                  <span className={Styles.brandName}>
                    {productDetails.product_brand}
                  </span>
                  <div className={Styles.categoryDiv1}>
                    {productDetails.product_category}
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "32px",
                    fontWeight: "700",
                    lineHeight: "40.7px",
                    marginTop: "10px",
                    fontFamily: "Google Sans",
                    marginBottom: "10px",
                    color: "#30263e",
                  }}
                >
                  <span>{productDetails.product_name}</span>
                </div>
                <div className={Styles.productPriceStyle}>
                  <span>BHD</span>
                  {productDetails.prod_discount === 0 ? (
                    <div className={Styles.productPriceDiv}>
                      {productDetails.product_unit_price}
                    </div>
                  ) : (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className={Styles.productPriceDiv}>
                        {productDetails?.product_price_after_discount?.toFixed(
                          2
                        )}
                      </div>
                      <span style={{ textDecoration: "line-through" }}>
                        /
                        {typeof productDetails.product_unit_price === "number"
                          ? productDetails.product_unit_price.toFixed(2)
                          : productDetails.product_unit_price}
                      </span>
                    </div>
                  )}
                </div>
                <div className={Styles.descDiv}>
                  <span>DESCRIPTION</span>
                </div>

                <div
                  className={Styles.detailDescDiv}
                  dangerouslySetInnerHTML={{
                    __html: isLongDescription
                      ? `${productDetails.product_description
                          .split(" ")
                          .slice(0, 80)
                          .join(" ")}...`
                      : productDetails.product_description,
                  }}
                ></div>
                <div className={Styles.commonDiv}>
                  <p
                    className="readMore"
                    onClick={() => setShowFullDescription(true)}
                  >
                    Continue Reading
                  </p>
                </div>
                <Drawer
                  title="Full Description"
                  placement="right"
                  closable={true}
                  onClose={() => setShowFullDescription(false)}
                  open={showFullDescription}
                  width={600}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: productDetails.product_description,
                    }}
                    className={Styles.serviceDescription}
                  ></div>
                </Drawer>
                <Divider />
                <Box className={Styles.borderStyleDiv}>
                  <span>AVAILABLE UNITS: {productDetails.available_unit}</span>
                  <span className={Styles.separator}>|</span>

                  <span style={{ alignItems: "left" }}>
                    SOLD UNITS : {productDetails.sold_units}
                  </span>
                </Box>
                <Divider sx={{ marginBottom: "15px" }} />
                {productDetails && (
                  <ProductDetailsTable
                    productData={productDetails}
                    onRowSelection={handleClickRow}
                    getProductDetails={getProductDetails}
                  />
                )}

                <Button className={Styles.addColorButton} onClick={showDrawer}>
                  <span className={Styles.addColorButtonText}>+ Add</span>
                </Button>

                <Divider />
                <Box className={Styles.borderStyleDiv}>
                  STYLE <span>{productDetails.product_style}</span>
                  <span className={Styles.separator}>|</span>
                  SKU No. <span>{productDetails.product_sku_id}</span>
                </Box>
                <Divider sx={{ marginBottom: "15px" }} />

                <div className={Styles.headingDiv}>
                  <span>Collaborations</span>
                </div>

                <ProductCollaborationGrid data={productDetails} />
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "30px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: "32px",
                      fontWeight: "700",
                      fontFamily: "Google Sans",
                      display: "flex",
                      flexDirection: "row",
                      color: "#30263e",
                    }}
                  >
                    (#)Reviews
                    <div className={Styles.categoryDiv1}>
                      <Star
                        sx={{
                          color: "#FFB800",
                        }}
                      />{" "}
                      0.0
                    </div>
                  </div>
                  <a
                    style={{
                      textDecoration: "underline",
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#30263E",
                    }}
                  >
                    SEE ALL
                  </a>
                </div>
                <Box>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              </Item>
            </Grid>
          </Grid>
        </Content>
      </Layout>

      <Drawer
        title="Add Product Variant"
        placement="right"
        onClose={onClose}
        open={open}
        width={"550px"}
        closable={false}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={drawerForm} onFinish={onFinish}>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Add Color Shade"
                name="hexValue"
                // rules={[{ required: true, message: "Please select a color" }]}
              >
                <Input
                  value={hexString}
                  className={Styles.formInput}
                  onFocus={() => setColorPickerVisible(!colorPickerVisible)}
                  onClick={() => setColorPickerVisible(!colorPickerVisible)}
                  suffix={
                    <ColorPicker
                      autoAdjustOverflow
                      className={Styles.colorPicker}
                      format={formatHex}
                      value={colorHex}
                      onChange={onColorChange}
                      onFormatChange={setFormatHex}
                      open={colorPickerVisible}
                    />
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="color"
                label="Color"
                // rules={[{ required: true, message: "Please select a color" }]}
              >
                <Input
                  className={Styles.formInput}
                  placeholder="Enter color"
                  onClick={() => setColorPickerVisible(false)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                name="size"
                label="Size"
                rules={[{ required: true, message: "Please select a size" }]}
              >
                <Cascader
                  options={menu}
                  className={Styles.formInput}
                  placeholder="Select a size"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="quantity"
                label="Quantity"
                rules={[{ required: true, message: "Please enter quantity" }]}
              >
                <InputNumber
                  min={1}
                  className={Styles.formInput}
                  placeholder="Enter quantity"
                  onClick={() => setColorPickerVisible(false)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="description"
            label="Description"
            hasFeedback
            style={{ width: "100%" }}
          >
            <RichTextEditor
              initialContent={editorContent1}
              onChange={handleEditorChange1}
            />
          </Form.Item>

      

          <Form.Item name="images" label="Images">
            <FileUpload
              title1="Upload Image"
              description="PDF, JPG, PNG file with max size of 5MB."
              setData={updateServiceImagesMain}
              existingPreviews={imagePreviews}
              setExistingPreviews={setImagePreviews}
              name="productImages"
              uploadMultiple
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              className={Styles.addButton}
              htmlType="submit"
              // onClick={() => setColorPickerVisible(false)}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Drawer>

      <EditProductDetails
        visible={visibleEditDrawer}
        onClose={closeEditProductDrawer}
        data={editData}
        id={id}
      />
    </Layout>
  );
};

export default ProductDetails;
