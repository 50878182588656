import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory, matchPath } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Styles from "./DashboardNavBar.module.css";
import { Col, Row, Menu, Dropdown, Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import OctopusNavLogo from "../../assests/headerLogoSupplier.svg";
import {
  NavbarRoutesAll,
  NavbarRoutesGuest,
  NavbarRoutesProvider,
} from "../../constant/NavbarRoutes";
import SearchBtn from "../SearchBtn/SearchBtn";
import Chat from "../../assests/customIcons/Chat";
import Notification from "../../assests/customIcons/Notification";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { RootState, STORE } from "../../redux/store";
import Logo from "../../assests/logo.svg";
import ServicesLogo from "../../assests/Services.svg";
import StoreLogo from "../../assests/Store.svg";
import OrderLogo from "../../assests/Booking.svg";
import ChatLogo from "../../assests/Chats.svg";
import EventLogo from "../../assests/Event.svg";
import MobileSearchBtn from "../SearchBtn/MobileSearchBtn";
import UserIcon from "../../assests/UserIcon.svg";
import LogOutIcon from "../../assests/logOutSvg.svg";
import logoMain from "../../assests/logo.svg";
import { _GET, getRole, getUserId } from "../commonFunc";
import FilterModal from "../SearchBtn/Filter/Filtermodal";
import { useSearch } from "./Search/SearchContext";
import ChatIcon from "../../assests/customIcons/ChatIcon";
import { ShoppingCartCheckout } from "@mui/icons-material";
import CartDrawer from "./cart/Cart";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  fetchCartItems,
  removeFromCart,
} from "../../redux/actions/cartActions";
import LoginPopUp from "../../pages/Login/LoginPopup";

const defaultUserDetails = {
  first_name: "Guest",
  last_name: "",
  profile_pic: "https://picsum.photos/200",
  id: "undefined",
};

const DashboardNavBar = ({
  showFilters,
  toggleFilters,
  filterTypes,
  suggestions,
}: any) => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const userId = getUserId();
  const dispatch = useDispatch();
  const history = useHistory();
  const [appliedFilters, setAppliedFilters] = useState<any>([]);
  const { setSearchQuery, setFilters } = useSearch();
  const UserDetailsLS: any = localStorage.getItem("userInfo");
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);

  const filterModalVariants = {
    hidden: { opacity: 0, translateY: -50, translateZ: 0 },
    visible: { opacity: 1, translateY: 0, translateZ: 0 },
  };

  const globalCartItems = useSelector(
    (state: RootState) => state.globalCart.items
  );
  const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);
  const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);

  useEffect(() => {
    dispatch(fetchCartItems());
  }, [dispatch]);

  const handleAddToCart = (item: any) => {
    dispatch(addToCart(item));
  };

  const handleRemoveFromCart = (itemId: string) => {
    dispatch(removeFromCart(itemId));
  };

  const handleOpenCart = () => {
    setIsCartOpen(true);
  };

  const handleCloseCart = () => {
    setIsCartOpen(false);
  };

  const handleFilterApply = (filters: any) => {
    setAppliedFilters(filters);
    setFilters(filters);
    // toggleFilters(false);
  };

  const toggleModal = () => {
    toggleFilters(!toggleFilters);
  };

  const [userRole, setUserrole] = useState(getRole());
  useEffect(() => {
    const userrole: any = getRole();
    setUserrole(userrole);
  });

  const NavbarRoutes =
    userRole === "guest"
      ? NavbarRoutesGuest
      : userRole === "freelancer"
      ? NavbarRoutesProvider
      : NavbarRoutesAll;

  const UserDetails = JSON.parse(UserDetailsLS)
    ? JSON.parse(UserDetailsLS)
    : defaultUserDetails;
  const [avatarColor, setAvatarColor] = useState("");

  if (!UserDetailsLS) {
    localStorage.setItem("userInfo", JSON.stringify(defaultUserDetails));
    localStorage.setItem("octo_role", "guest");
  }

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  useEffect(() => {
    // Retrieve the color from Local Storage or generate a new one
    let color = localStorage.getItem("avatarColor");
    if (!color) {
      color = getRandomColor();
      localStorage.setItem("avatarColor", color);
    }
    setAvatarColor(color);
  }, []);

  const renderAvatar = () => {
    if (UserDetails.profile_pic && UserDetails.profile_pic !== "NULL") {
      return (
        <img
          src={UserDetails.profile_pic}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
          alt="avatar"
        />
      );
    } else {
      const initial = UserDetails.first_name ? UserDetails.first_name[0] : "";
      const last = UserDetails.last_name ? UserDetails.last_name[0] : "";
      const comp = initial + last;
      const bgColor = getRandomColor();
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: avatarColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {comp.toUpperCase()}
        </div>
      );
    }
  };

  const handleLogout = () => {
    STORE.dispatch({ type: "LOGOUT_USER" });
    window.location.href = APPLICATION_ROUTES.LOGIN;
  };

  const handleProfileSettings = () => {
    history.push(APPLICATION_ROUTES.PROFILE);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const dynamicRouteMap: any = {
    "/productDetails": "/store",
    "/service": "/service",
    "/eventDetails": "/events",
  };

  const getMappedRoute = (pathname: any) => {
    for (const key in dynamicRouteMap) {
      if (pathname.startsWith(key)) {
        return dynamicRouteMap[key];
      }
    }
    return pathname;
  };

  const hiddenRoutes = [
    "/login",
    "/register",
    "/service/:id",
    "/eventDetails/:id",
  ];

  const isRouteHidden = (pathname: any) => {
    return hiddenRoutes.some((route) =>
      matchPath(pathname, { path: route, exact: true })
    );
  };

  const shouldHideNavBarBottom = isRouteHidden(location.pathname);

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div
              onClick={handleProfileSettings}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "25px",
                margin: 0,
              }}
            >
              <img
                src={UserIcon}
                alt=""
                style={{
                  height: "15px",
                  width: "15px",
                  backgroundColor: "#F5F4FA",
                  padding: "2px",
                  borderRadius: "50%",
                  marginRight: "5px",
                }}
              />
              <p>Profile</p>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              onClick={handleLogout}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "25px",
                margin: 0,
              }}
            >
              <img
                src={LogOutIcon}
                alt=""
                style={{
                  height: "15px",
                  width: "15px",
                  backgroundColor: "#F5F4FA",
                  padding: "2px",
                  borderRadius: "50%",
                  marginRight: "5px",
                }}
              />
              <p>Logout</p>
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <div
        className={`${Styles.dashboardNavBarContainer} ${
          showFilters ? Styles.open : ""
        }`}
      >
        <Row className={Styles.navbarRow}>
          <Col span={3}>
            <img
              src={OctopusNavLogo}
              alt=""
              style={{ width: "90%", height: "90%", cursor: "pointer" }}
              onClick={() => history.push(APPLICATION_ROUTES.DASHBOARD)}
            />
          </Col>
          <Col span={4} style={{ marginRight: "10px" }}>
            <SearchBtn
              filterTypes={filterTypes}
              toggleFilters={toggleFilters}
              suggestions={suggestions}
            />
          </Col>
          <Col span={15} className={Styles.rightNav}>
            <Row style={{ width: "100%", height: "100%" }}>
              <div className={Styles.navLinks}>
                {NavbarRoutes.map(({ id, route, label }) => {
                  const currentRoute = getMappedRoute(location.pathname);
                  const isRouteActive = currentRoute === route;

                  return (
                    <Link
                      to={route}
                      className={`${Styles.navLink} ${
                        isRouteActive ? Styles.activeLink : ""
                      }`}
                      key={id}
                      style={{
                        pointerEvents: label !== "Store" ? "auto" : "auto",
                      }}
                    >
                      {label}
                    </Link>
                  );
                })}
              </div>

              <div className={Styles.navIcons}>
                {userRole !== "freelancer" && (
                   <button
                   onClick={() =>
                     userRole !== "guest"
                       ? handleOpenCart()
                       : history.push(APPLICATION_ROUTES.LOGIN)
                   }
                   className="elevatedElement iconBtn"
                   style={{ position: "relative" }}
                 >
                   <ShoppingCartCheckout />
                   <div className={Styles.countOnIcon}>
                     {globalCartItems.length || 0}
                   </div>
                 </button>
                )}
               
                <button
                  onClick={() =>
                    userRole !== "guest"
                      ? history.push(APPLICATION_ROUTES.CHAT_PAGE)
                      : history.push(APPLICATION_ROUTES.LOGIN)
                  }
                  className="elevatedElement iconBtn"
                  style={{ position: "relative" }}
                >
                  <ChatIcon />
                  <div className={Styles.countOnIcon}>0</div>
                </button>
                <button
                  onClick={() => {}}
                  className="elevatedElement iconBtn"
                  style={{ position: "relative" }}
                >
                  <Notification />
                  <div className={Styles.countOnIcon}>0</div>
                </button>
                {userRole !== "guest" ? (
                  <Dropdown
                    overlay={menu}
                    placement="bottomCenter"
                    trigger={["click"]}
                  >
                    <button
                      className="elevatedElement iconBtn"
                      style={{
                        padding: 5,
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                    >
                      {renderAvatar()}
                    </button>
                  </Dropdown>
                ) : (
                  <Link to={"/login"} className={Styles.navLink}>
                    Login/SignUp
                  </Link>
                )}
              </div>
            </Row>
          </Col>
        </Row>
        <AnimatePresence>
          {showFilters && (
            <motion.div
              className={Styles.filterOptions}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={filterModalVariants}
              transition={{ duration: 0.3, ease: "backInOut" }}
            >
              <FilterModal
                filterOptions={filterTypes}
                initialFilters={appliedFilters}
                onApply={handleFilterApply}
                onCancel={toggleModal}
                isVisible={showFilters}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {!shouldHideNavBarBottom && (
        <div className={Styles.dashboardNavBarContainerBottom}>
          <Row className={Styles.navbarRow}>
            <Col span={4}>
              <Link
                className={
                  location.pathname === "/service" ? "selectedLink" : ""
                }
                to={"/service"}
                style={{
                  color: location.pathname === "/service" ? "" : "gray",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={ServicesLogo}
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      filter:
                        location.pathname === "/service"
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                    }}
                  />

                  <Link
                    className={
                      location.pathname === "/service" ? "selectedLink" : ""
                    }
                    to={"/service"}
                    style={{
                      color: location.pathname === "/service" ? "" : "gray",
                    }}
                  >
                    Services
                  </Link>
                </div>
              </Link>
            </Col>
            <Col span={4} style={{ pointerEvents: "none", opacity: 0.5 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={StoreLogo}
                  alt=""
                  style={{
                    width: "35px",
                    height: "35px",
                    filter: "grayscale(100%)",
                  }}
                />

                <Link style={{ color: "gray" }} to={"/service"}>
                  Store
                </Link>
              </div>
            </Col>

            <Col span={4}>
              <Link
                className={
                  location.pathname === "/dashboard" ? "selectedLink" : ""
                }
                to={"/dashboard"}
                style={{
                  color: location.pathname === "/dashboard" ? "" : "gray",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Logo}
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      filter:
                        location.pathname === "/dashboard"
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                    }}
                  />

                  <Link
                    className={
                      location.pathname === "/dashboard" ? "selectedLink" : ""
                    }
                    to={"/dashboard"}
                    style={{
                      color: location.pathname === "/dashboard" ? "" : "gray",
                    }}
                  >
                    Home
                  </Link>
                </div>
              </Link>
            </Col>

            <Col span={4}>
              <Link
                className={location.pathname === "/order" ? "selectedLink" : ""}
                to={"/order"}
                style={{
                  color: location.pathname === "/order" ? "" : "gray",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={OrderLogo}
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      filter:
                        location.pathname === "/order"
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                    }}
                  />

                  <Link
                    className={
                      location.pathname === "/order" ? "selectedLink" : ""
                    }
                    to={"/order"}
                    style={{
                      color: location.pathname === "/order" ? "" : "gray",
                    }}
                  >
                    Order
                  </Link>
                </div>
              </Link>
            </Col>

            <Col span={4}>
              <Link
                className={location.pathname === "/evens" ? "selectedLink" : ""}
                to={"/events"}
                style={{
                  color: location.pathname === "/events" ? "" : "gray",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={EventLogo}
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      filter:
                        location.pathname === "/events"
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                    }}
                  />

                  <Link
                    className={
                      location.pathname === "/evens" ? "selectedLink" : ""
                    }
                    to={"/events"}
                    style={{
                      color: location.pathname === "/events" ? "" : "gray",
                    }}
                  >
                    Events
                  </Link>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      )}
      <CartDrawer visible={isCartOpen} onClose={handleCloseCart} />
      {/* <LoginPopUp open={isLoginPopupOpen} onClose={handleCloseLoginPopup} /> */}
    </>
  );
};

export default DashboardNavBar;
