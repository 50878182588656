import react, { useEffect, useMemo, useState } from "react";
import {
  Breadcrumb,
  Button,
  Layout,
  Drawer,
  Space,
  Switch,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  notification,
  Row,
  Col,
  ColorPicker,
  Popover,
  Dropdown,
  Menu,
  Cascader,
  Radio,
  Upload,
  Table,
} from "antd";

import { useHistory } from "react-router";
import Cross from "../../../assests/customIcons/Cross";
import PlusIcon from "../../../assests/PlusIcon.svg";
import excelIcon from "../../../assests/CSV.png";
import editIcon from "../../../assests/EditIcon.svg";
import deleteIcon from "../../../assests/DeleteIcon.svg";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import Styles from "./MyStore.module.css";
import FileUpload from "../../../components/InputComponents/FileUpload";
import {
  InfoCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { _GET, _POST } from "../../../common/commonFunc";
import RichTextEditor from "../../../components/RichText/Quill2";
import ImgCrop from "antd-img-crop";
import DynamicSelectComponentProd from "../../../common/DynamicSelectcomponent/DynamicSelectProd";
import { values } from "lodash";
import DynamicSelectBrand from "../../../common/DynamicSelectcomponent/DynamicSelectBrand";
import { Tooltip, Typography } from "@mui/material";
import AddLogisticPartner from "./AddLogisticParnter";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

interface SkuRow {
  key: number;
  sku: string;
  barCode: string;
}

const AddNewProduct = () => {
  const [form] = Form.useForm();
  const [drawerForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState<any>([]);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [editorContent, setEditorContent] = useState<any>(null);
  const [editorContent1, setEditorContent1] = useState<any>(null);
  const [editorContentMore, setEditorContentMore] = useState<any>(null);
  const [attributes, setAttributes] = useState<any>({});
  const [imagePreviews, setImagePreviews] = useState([]);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [prodSpecs, setProdSpecs] = useState<any>([]);
  const [editingVariantId, setEditingVariantId] = useState(null);

  const [specsImages, setSpecsImages] = useState<any>([]);
  const [attributeId, setAttributeId] = useState<any>();
  const [prodImages, setProdImages] = useState<any>([]);
  const [drawerKey, setDrawerKey] = useState(0);
  const [uniqueValues, setUniqueValues] = useState([123456789]);
  const [selectedCategory, setSelectedCategory] = useState({
    id: "",
    name: "",
  });
  const [selectedSubCategory, setSelectedSubCategory] = useState({
    id: "",
    name: "",
  });
  const [selectedBrand, setSelectedBrand] = useState({
    id: "",
    name: "",
  });
  console.log("🚀 ~ AddNewProduct ~ selectedBrand:", selectedBrand);
  const [selectedUnit, setSelectedUnit] = useState({
    id: "",
    name: "",
  });

  const [colorHex, setColorHex] = useState<any>("#1677ff");
  const [formatHex, setFormatHex] = useState<any>("hex");

  const [courierCharge, setCourierCharge] = useState<string | null>(null);
  const [courierPartner, setCourierPartner] = useState<string | null>(null);
  const [addCourierDrawer, setAddCourierDrawer] = useState<boolean>(false);
  const [skuRows, setSkuRows] = useState<SkuRow[]>([]);
  const [quantity, setQuantity] = useState<number | null>(null);

  const handleQuantityChange = (value: any) => {
    setQuantity(value);
    if (value <= 10) {
      const rows = Array.from({ length: value }, (_, index) => ({
        key: index,
        sku: "",
        barCode: "",
      }));
      setSkuRows(rows);
    } else {
      setSkuRows([]);
    }
  };

  const handleFileUpload = (info: any) => {};

  const downloadTemplate = () => {};

  const hexString = useMemo<string>(
    () => (typeof colorHex === "string" ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );

  const handleCategoryChange = (value: any) => {
    const category = categories.find(
      (cat: any) => cat.products_category_id === value
    );
    setSelectedCategory({
      id: category.products_category_id,
      name: category.name,
    });
  };

  const [selectedCategoryId, setSelectedCategoryId] = useState<any>(null);
  const handleSubCategoryChange = (value: any) => {
    console.log("🚀 ~ handleSubCategoryChange ~ value:", value);
    const subCategory: any = subCategories.find(
      (sub: any) => sub.sub_category_id === value
    );
    setSelectedSubCategory({
      id: subCategory.sub_category_id,
      name: subCategory.sub_category_name,
    });
  };

  const handleBrandChange = (value: any) => {
    console.log("🚀 ~ handleBrandChange ~ value:", value);
    setSelectedBrand({
      id: value.brand_id,
      name: value.name,
    });
    if (value) {
      form.setFieldsValue({ prod_brand: value.name });
    }
  };

  const handleCourierChargeChange = (event: any) => {
    setCourierCharge(event.target.value);
    setCourierPartner(null);
  };

  const handleCourierPartnerChange = (event: any) => {
    setCourierPartner(event.target.value);
  };

  const handleAddCourier = () => {
    setAddCourierDrawer(true);
  };

  const closeAddCourier = () => {
    setAddCourierDrawer(false);
  };

  console.log("selectedBrand", selectedBrand);

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((ele: any) => {
        if (ele === "images") {
          data[ele].forEach((image: any) => {
            formData.append("images", image);
          });
        } else {
          formData.append(ele, data[ele]);
        }
      });
    }
    return formData;
  };

  const updateServiceImages = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setSpecsImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = specsImages;
      updatedImages.splice(index, 1);
      setSpecsImages(updatedImages);
    }
  };

  const updateServiceImagesMain = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setProdImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = prodImages;
      updatedImages.splice(index, 1);
      setProdImages(updatedImages);
    }
  };

  const getUpdatedImages = async () => {
    if (specsImages.length > 0) {
      const allValues = {
        images: specsImages,
      };

      const mainValues = await convertDataToFormData(allValues);

      const response = await _POST(
        "services/uploadServiceImagesToS3",
        mainValues,
        true,
        false
      );
      if (response) {
        if (response.data?.length > 0) {
          return response.data;
        } else {
          notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please try again to upload your images",
          });
        }
      }
    } else {
      return [];
    }
  };

  const getUpdatedImagesMain = async () => {
    if (prodImages.length > 0) {
      const allValues = {
        images: prodImages,
      };

      const mainValues = await convertDataToFormData(allValues);

      const response = await _POST(
        "services/uploadServiceImagesToS3",
        mainValues,
        true,
        false
      );
      if (response) {
        if (response.data?.length > 0) {
          return response.data;
        } else {
          notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please try again to upload your images",
          });
        }
      }
    } else {
      return [];
    }
  };

  const onColorChange = (color: any) => {
    setColorHex(color);
    drawerForm.setFieldsValue({ hexValue: color.toHexString() });
    // setColorPickerVisible(!colorPickerVisible);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setEditingVariantId(null);
    drawerForm.resetFields();
    setSpecsImages([]);
    setColorHex("#1677ff");
    setImagePreviews([]);
  };

  const history = useHistory<any>();
  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const onFinish = async (values: any) => {
    // console.log('checking values on submit', values, courierPartner);
    if (prodSpecs?.length === 0) {
      notification.error({
        message: "Error",
        description: "Please add the size, quantity and other details",
      });
      return;
    }
    const uploadedImages = await getUpdatedImagesMain();

    const isReturnable = values.is_returnable ? "true" : "false";

    if (uploadedImages?.length === 0) {
      notification.error({
        message: "Error",
        description: "Please add at least one product image",
      });
      return;
    }

    const payload = {
      prod_category: selectedCategory.name,
      prod_category_id: selectedCategory.id,
      prod_sub_category: selectedSubCategory.name,
      prod_sub_category_id: selectedSubCategory.id,
      // prod_sku_id: values.prod_sku_id.toString(),
      prod_attribute_id: attributeId,
      product_name: values.product_name,
      description: values.description,
      other_specs: values.other_specs,
      prod_brand: selectedBrand.name,
      prod_unit_price: values.prod_unit_price,
      prod_style: values.prod_style,
      prod_discount: values.prod_discount?.toString() || "0",
      is_returnable: isReturnable,
      prod_specs: prodSpecs,
      prod_images: uploadedImages,
      courier_service_choice: courierPartner,
      courier_charges:
        courierPartner === "supplier_courier_service"
          ? values?.courier_charge
          : 0.0,
    };
    console.log("Payload to create product is : ", payload);
    const response = await _POST(
      "supplier/product/create/product",
      payload,
      true,
      false
    );
    if (response) {
      if (response.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        history.push("/supplier/mystore");
      } else {
        notification.error({
          message: response.message,
        });
      }
    }
  };

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
  };
  const handleEditorChange1 = (content: any) => {
    setEditorContent1(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
  };
  const handleEditorChangeMore = (content: any) => {
    setEditorContentMore(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
  };
  const renderProductSpecs = () => {
    return (
      <div className={Styles.specsTable}>
        <Row style={{ marginBottom: "0.5rem" }} className={Styles.specRowHead}>
          <Col span={4} className={Styles.specCell}>
            Color
          </Col>
          <Col span={4} className={Styles.specCell}>
            Size
          </Col>
          <Col span={4} className={Styles.specCell}>
            In store Qty
          </Col>
          <Col span={4} className={Styles.specCell}>
            Assignees
          </Col>
          <Col span={4} className={Styles.specCell}>
            Assigned QTY
          </Col>
        </Row>
        {prodSpecs.map((spec: any, index: any) => (
          <Row key={index} className={Styles.specRow}>
            <Col span={1}>
              <div
                className={Styles.colorSwatch}
                style={{ backgroundColor: spec.hexValue }}
              ></div>
            </Col>
            <Col span={3} className={Styles.specCell}>
              {spec.color}
            </Col>
            <Col span={5} className={Styles.specCell}>
              {spec.size}
            </Col>
            <Col span={4} className={Styles.specCell}>
              {spec.quantity}
            </Col>
            <Col span={4} className={Styles.specCell}>
              -
            </Col>
            <Col span={3} className={Styles.specCell}>
              0/{spec.quantity}
            </Col>
            <Col span={4} className={`${Styles.specCell} ${Styles.actionCell}`}>
              <Button className={Styles.addButton}>Assign</Button>

              <Button
                icon={<img src={editIcon} />}
                onClick={() => handleEditVariant(spec)}
                className={Styles.editButton}
              />

              <Button
                className={Styles.editButton}
                icon={<img src={deleteIcon} />}
                onClick={() => deleteVariant(spec.id)}
              />
            </Col>
          </Row>
        ))}
      </div>
    );
  };

  const getProductsCetgories = async () => {
    try {
      const res = await _GET("supplier/product/fetch/categories", false, false);
      if (res.statusCode === 200) {
        setCategories(res.data);
      } else {
        notification.error({
          message: res.message,
        });
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  const getProductSubCategories = async (categoryId: string) => {
    try {
      const res = await _GET(
        `supplier/product/fetch/sub/categories?category_id=${categoryId}`,
        false,
        false
      );
      if (res.statusCode === 200) {
        setSubCategories(res.data);
      } else {
        notification.error({
          message: res.message,
        });
      }
    } catch (error) {
      console.error("error:", error);
      notification.error({
        message: "Failed to fetch subcategories",
      });
    }
  };

  const getProductAttributes = async (categoryId: string) => {
    try {
      const res = await _GET(
        `supplier/product/fetch/attributes?category_id=${categoryId}`,
        false,
        false
      );

      if (res.statusCode === 200) {
        setAttributes(res.data.attribute_features);
        setAttributeId(res.data.prod_attribute_id);
      }
    } catch (error) {
      console.error("error:", error);
      notification.error({
        message: "Failed to fetch attributes",
      });
    }
  };

  useEffect(() => {
    getProductsCetgories();
  }, []);

  const deleteVariant = (id: any) => {
    setProdSpecs(prodSpecs.filter((spec: any) => spec.id !== id));
  };

  const handleEditVariant = (spec: any) => {
    setEditingVariantId(spec.id);
    setImagePreviews(spec.images);
    if (spec.sku_details) {
      setSkuRows(
        spec.sku_details.map((detail: any, index: number) => ({
          key: index,
          sku: detail.sku_number,
          barCode: detail.barcode_number,
        }))
      );
    }

    drawerForm.setFieldsValue({
      hexValue: spec.hexValue,
      color: spec.color,
      size: spec.size,
      quantity: spec.quantity,
    });
    setColorHex(spec.hexValue ?? "#fff");

    setOpen(true);
  };

  const validatePrice = async (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error("Please enter a price!"));
    }
    const numValue = parseFloat(value);
    if (numValue <= 0) {
      return Promise.reject(new Error("Price must be greater than zero."));
    }
    const regex = /^\d{1,13}(\.\d{0,3})?$/;
    if (!regex.test(value)) {
      return Promise.reject(new Error("Price must be less than 1 trillion."));
    }
    return Promise.resolve();
  };

  const checkUnique = (_: any, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (uniqueValues.includes(value)) {
        return reject(new Error("The value must be unique."));
      }
      resolve();
    });
  };

  const getProductBrands = async (categoryId: string) => {
    try {
      const res = await _GET(
        `supplier/product/fetch/brands?category_id=${categoryId}`,
        false,
        false
      );
      if (res.statusCode === 200) {
        console.log(res.data);
      } else {
        notification.error({
          message: res.message,
        });
      }
    } catch (error) {
      console.error("error:", error);
      notification.error({
        message: "Failed to fetch brands",
      });
    }
  };

  const handleScroll = (event: any) => {
    event.target.blur();
  };
  // const system = attributes.systemValues?.length ? attributes.systemValues : ["Size Category"];
  // const sizesBySystem =
  //   attributes?.sizes?.reduce((acc: any, size: any) => {
  //     system.forEach((system: string) => {
  //       if (!acc[system] || acc[system]?.length === 0) {
  //         acc[system] = [];
  //       }
  
  //       if (!acc[system].find((s: any) => s.size === size.size)) {
  //         acc[system].push(size);
  //       }
  //     });
  //     console.log("---------------- acc -------------", acc)
  //     return acc;
  //   }, {}) ?? {};

  const menu = Object.keys(attributes?.sizes ?? {}).map((system) => ({
    value: system,
    label: system,
    children: attributes.sizes[system].map((size: any) => ({
      value: size.size,
      label: `${size.size} - ${size.description}`,
    })),
  }));

  menu.push({
    value: "NA",
    label: "Not Applicable",
    children: [],
  });
  // console.log("size by system is ", sizesBySystem);
  console.log("menu is ", menu);

  const columns = [
    {
      title: "SKU Number",
      dataIndex: "sku",
      key: "sku",
      render: (_: any, record: any) => (
        <Input
          placeholder="Enter SKU"
          value={record.sku}
          type="number"
          maxLength={9}
          onChange={(e) => {
            const newRows = [...skuRows];
            newRows[record.key].sku = e.target.value;
            setSkuRows(newRows);
          }}
          onWheel={handleScroll}
        />
      ),
    },
    {
      title: "Bar Code",
      dataIndex: "barCode",
      key: "barCode",
      render: (_: any, record: any) => (
        <Input
          placeholder="Enter Bar Code"
          value={record.barCode}
          type="number"
          maxLength={10}
          onChange={(e) => {
            const newRows = [...skuRows];
            newRows[record.key].barCode = e.target.value;
            setSkuRows(newRows);
          }}
          onWheel={handleScroll}
        />
      ),
    },
  ];

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA !important",
        background: "#F5F4FA !important",
      }}
      className={Styles.customLayoutBG}
    >
      <div className={Styles.header}>
        <h1>Add new Product</h1>
        <Button className={Styles.addARButton}>
          AR Mode
          <Switch />
        </Button>
      </div>

      <Breadcrumb
        style={{ margin: "16px 0" }}
        separator={<img src={breadcrumbIcon} alt=">" />}
      >
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/supplier/mystore")}
        >
          My Store
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add product</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{ height: "auto", width: "100%" }}
        className={Styles.parentContentdiv}
      >
        <div className={Styles.leftDiv}>
          <FileUpload
            title1="Upload Image"
            description="PDF, JPG, PNG file with max size of 5MB."
            setData={updateServiceImagesMain}
            name="serviceImages"
            uploadMultiple
          />
        </div>
        <div className={Styles.rightDiv}>
          <Form
            form={form}
            name="product_form"
            onFinish={onFinish}
            layout="vertical"
            className={Styles.form}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="prod_category"
                  label="Select category"
                  rules={[{ required: true }]}
                  className={Styles.formItem}
                >
                  <Select
                    placeholder="Select a category"
                    onChange={async (value) => {
                      await getProductSubCategories(value);
                      await getProductAttributes(value);
                      handleCategoryChange(value);
                      setSelectedCategoryId(value);
                    }}
                    options={categories.map((category: any) => ({
                      label: capitalizeFirstLetter(category.name),
                      value: category.products_category_id,
                    }))}
                    className={Styles.formSelect}
                    showSearch
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="product_name"
                  label="Product name"
                  rules={[{ required: true }]}
                  className={Styles.formItem}
                >
                  <Input
                    className={Styles.formInput}
                    placeholder="Enter name"
                    maxLength={50}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* <Form.Item
                  name="prod_sub_category"
                  label="Select subcategory"
                  rules={[{ required: true }]}
                  className={Styles.formItem}
                > */}
                {/* <Select
                    placeholder="Select a subcategory"
                    onChange={handleSubCategoryChange}
                    options={subCategories.map((subCategory: any) => ({
                      label: subCategory.sub_category_name,
                      value: subCategory.sub_category_id,
                    }))}
                    className={Styles.formSelect}
                    showSearch
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  /> */}
                <DynamicSelectComponentProd
                  endpoint={`supplier/product/fetch/sub/categories?category_id=${selectedCategoryId}`}
                  type="subcategory"
                  onChange={(value: any) => handleSubCategoryChange(value)}
                  disabled={!selectedCategoryId}
                  selectedCategoryId={selectedCategoryId}
                  setSelected={(itemName: string) => console.log(itemName)}
                  onAdd={() => getProductSubCategories(selectedCategoryId)}
                />
                {/* </Form.Item> */}

                {/* <Form.Item
                  name="prod_sku_id"
                  label="SKU No."
                  rules={[
                    {
                      required: true,
                      message: "Please input the SKU!",
                    },
                    {
                      pattern: new RegExp(/^\d{9}$/),
                      message: "SKU must be exactly 9 digits.",
                    },
                    {
                      validator: checkUnique,
                    },
                  ]}
                  className={Styles.formItem}
                >
                  <InputNumber
                    min={0}
                    className={Styles.formInput}
                    placeholder="Enter SKU"
                  />
                </Form.Item> */}
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Form.Item
                name="description"
                label="Description"
                hasFeedback
                style={{ width: "100%" }}
                rules={[{ required: true }]}
              >
                <RichTextEditor
                  initialContent={editorContent}
                  onChange={handleEditorChange}
                />
              </Form.Item>
            </Row>
            <Row
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Form.Item
                name="other_specs"
                label="Other Specs"
                hasFeedback
                style={{ width: "100%" }}
                rules={[{ required: true }]}
              >
                <RichTextEditor
                  initialContent={editorContentMore}
                  onChange={handleEditorChangeMore}
                />
              </Form.Item>
            </Row>
            <div className={Styles.quantitySection}>
              <h2>Quantity</h2>
              <div>{renderProductSpecs()}</div>
              <Form.Item>
                <Button className={Styles.addColorButton} onClick={showDrawer}>
                  <span className={Styles.addColorButtonText}>+ Add</span>
                </Button>
              </Form.Item>
            </div>
            <Row gutter={24}>
              <Col span={12}>
                {/* <Select
                    placeholder="Select a brand"
                    className={Styles.formSelect}
                  /> */}
                {/* <Input
                    className={Styles.formInput}
                    placeholder="Select a brand"
                  /> */}
                <DynamicSelectBrand
                  endpoint={`supplier/product/fetch/brands?category_id=${selectedCategoryId}`}
                  type="brand"
                  onChange={(value: any) => handleBrandChange(value)}
                  disabled={!selectedCategoryId}
                  selectedCategoryId={selectedCategoryId}
                  setSelected={(itemName: string) => console.log(itemName)}
                  onAdd={() => getProductBrands(selectedCategoryId)}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  name="prod_style"
                  label="Style"
                  rules={[{ required: true }]}
                  className={Styles.formItem}
                >
                  <Input
                    className={Styles.formInput}
                    placeholder="Enter style"
                    maxLength={20}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="courier_partner"
              label="Please select your Logistic Partner"
              required={true}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Radio.Group
                  onChange={handleCourierPartnerChange}
                  value={courierPartner}
                >
                  <Radio value="octopus_courier_service">
                    Octopus's Courier service
                  </Radio>
                  <Radio value="supplier_courier_service">
                    {" "}
                    Other Courier service
                  </Radio>
                </Radio.Group>
              </div>
            </Form.Item>

            {courierPartner === "supplier_courier_service" && (
              <Form.Item
                name="courier_charge"
                label="Add Courier Charges"
                required={true}
              >
                <Input
                  className={Styles.formInput}
                  style={{ width: "50%" }}
                  placeholder="Enter Amount"
                  // value={fixedAmount || ""}
                  // onChange={e => handleFixedAmtChange(e.target.value)}
                  type="number"
                  onWheel={handleScroll}
                />
              </Form.Item>
            )}

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Unit Price"
                  name="prod_unit_price"
                  rules={[
                    { required: true, message: "" },

                    {
                      validator: validatePrice,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Enter price"
                    className={Styles.formInput}
                    onWheel={handleScroll}
                  />
                </Form.Item>
                <Typography variant="caption" ml={2} color={"red"}>
                  Unit Price is exclusive of VAT
                </Typography>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="prod_discount"
                  label="Discount ( % )"
                  className={Styles.inlineFormItem}
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "Discount cannot go beyond 100%",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className={Styles.formInput}
                    placeholder="Enter discount"
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* <Form.Item name="courier_charge" label="Is the price inclusive of courier charges?" required={true}>
              <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>
                <Radio.Group
                  onChange={handleCourierChargeChange}
                  value={courierCharge}
                >
                  <Radio value="courierChargeInclusive">Yes</Radio>
                  <Radio value="courierChargeExclusive"> No</Radio>
                </Radio.Group>
              </div>
            </Form.Item> */}

            <Form.Item
              name="is_returnable"
              valuePropName="checked"
              className={Styles.formItem}
              style={{ marginTop: "10px" }}
            >
              {/*  */}
              <Checkbox /> Check this if this product policy is not returnable{" "}
              <Tooltip title="Non returnable product">
                {<InfoCircleOutlined className={Styles.infoIcon} />}
              </Tooltip>
            </Form.Item>

            <Form.Item className={Styles.saveButton}>
              <Button htmlType="submit" className={Styles.addButton}>
                Publish
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <AddLogisticPartner
        visible={addCourierDrawer}
        onClose={closeAddCourier}
      />

      <Drawer
        title="Add Product Variant"
        placement="right"
        onClose={onClose}
        open={open}
        width={"550px"}
        closable={false}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
        key={drawerKey}
      >
        <Form
          layout="vertical"
          onFinish={async (values) => {
            const uploadedImageURLs = await getUpdatedImages();
            values.images = [...imagePreviews, ...uploadedImageURLs];

            const selectedSizeArray = values.size;
            let systemSize = "";
            if (selectedSizeArray && selectedSizeArray.length > 1) {
              const system = selectedSizeArray[0];
              const size = selectedSizeArray[1];

              systemSize = `${system}-${size}`;
            }
            if (
              systemSize === null ||
              systemSize === undefined ||
              systemSize === ""
            ) {
              systemSize = "NA";
            }

            if (quantity === skuRows.length) {
              const skuDetails = skuRows.map((details: any) => ({
                sku_number: details.sku,
                barcode_number: details.barCode,
              }));

              if (editingVariantId !== null) {
                setProdSpecs(
                  prodSpecs.map((spec: any) =>
                    spec.id === editingVariantId
                      ? {
                          ...spec,
                          ...values,
                          size: systemSize,
                          sku_details: skuDetails,
                        }
                      : spec
                  )
                );
              } else {
                const nextId = prodSpecs.length + 1;
                setProdSpecs([
                  ...prodSpecs,
                  {
                    ...values,
                    id: nextId,
                    size: systemSize,
                    sku_details: skuDetails,
                  },
                ]);
              }
            } else {
              notification.error({
                message: "Please fill in all the SKU numbers and Barcodes",
              });
              return;
            }
            // if (editingVariantId !== null) {
            //   setProdSpecs(
            //     prodSpecs.map((spec: any) =>
            //       spec.id === editingVariantId ? { ...spec, ...values, size: systemSize } : spec
            //     )
            //   );
            // } else {
            //   const nextId = prodSpecs.length + 1;
            //   setProdSpecs([...prodSpecs, { ...values, id: nextId, size: systemSize }]);
            // }

            // if (quantity === skuRows.length) {
            //   const allFilled = skuRows.every((details: any) => details.sku.trim() !== '' && details.barCode.trim() !== '');

            //   if (!allFilled) {
            //     notification.error({ message: "Please fill in all the SKU numbers and Barcodes" });
            //     return;
            //   }

            //   const skuDetails = skuRows.map((details: any) => ({
            //     sku_number: details.sku,
            //     barcode_number: details.barCode
            //   }));

            //   if (editingVariantId !== null) {

            //     setProdSpecs(
            //       prodSpecs.map((spec: any) =>
            //         spec.id === editingVariantId ? { ...spec, ...values, sku_details: skuDetails } : spec
            //       )
            //     );
            //   } else {
            //     const nextId = prodSpecs.length + 1;
            //     setProdSpecs([...prodSpecs, { ...values, id: nextId, sku_details: skuDetails }]);
            //   }
            // } else {
            //   notification.error({ message: "Please fill in all the SKU numbers and Barcodes" });
            //   return;
            // }

            setEditingVariantId(null);
            setImagePreviews([]);
            setOpen(false);
            // console.log("Value in skuRows is : ", skuRows)
          }}
          form={drawerForm}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="Add Color Shade"
                name="hexValue"
                // rules={[{ required: true, message: "Please select a color" }]}
              >
                <Input
                  value={hexString}
                  className={Styles.formInput}
                  onFocus={() => setColorPickerVisible(!colorPickerVisible)}
                  onClick={() => setColorPickerVisible(!colorPickerVisible)}
                  suffix={
                    <ColorPicker
                      autoAdjustOverflow
                      className={Styles.colorPicker}
                      format={formatHex}
                      value={colorHex}
                      onChange={onColorChange}
                      onFormatChange={setFormatHex}
                      open={colorPickerVisible}
                    />
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="color"
                label="Color"
                // rules={[{ required: true, message: "Please select a color" }]}
              >
                <Input
                  className={Styles.formInput}
                  placeholder="Enter color"
                  onClick={() => setColorPickerVisible(false)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                name="size"
                label="Size"
                rules={[{ required: true, message: "Please select a size" }]}
              >
                <Cascader
                  options={menu}
                  className={Styles.formInput}
                  placeholder="Select a size"
                  onClick={() => setColorPickerVisible(false)}
                  showSearch
                />
              </Form.Item>
            </Col>

            {/* <Form.Item
                name="quantity"
                label="Quantity"
                rules={[
                  { required: true, message: "Please enter quantity"},
                  { type: 'number', min: 0, message: 'Quantity must be a non-negative number' }
                
                ]}
              >
                <InputNumber
                  
                  className={Styles.formInput}
                  placeholder="Enter quantity"
                  onClick={() => setColorPickerVisible(false)}
                />
              </Form.Item> */}

            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[
                { required: true, message: "Please enter quantity" },
                {
                  type: "number",
                  min: 0,
                  message: "Quantity must be a non-negative number",
                },
              ]}
            >
              <InputNumber
                className={Styles.formInput}
                placeholder="Enter quantity"
                onClick={() => setColorPickerVisible(false)}
                onChange={handleQuantityChange}
              />
            </Form.Item>

            {quantity && (
              <Form.Item style={{ width: "100%" }}>
                {quantity <= 10 && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginBottom: "15px",
                    }}
                  >
                    <Table
                      columns={columns}
                      dataSource={skuRows}
                      pagination={false}
                      className={Styles.tableClass}
                    />
                  </div>
                )}

                {quantity > 10 && (
                  // <>
                  //   <FileUpload
                  //     title1="Upload Product Details"
                  //     description="PDF, JPG, PNG file with max size of 5MB."
                  //     setData={handleFileUpload}
                  //     existingPreviews={imagePreviews}
                  //     setExistingPreviews={setImagePreviews}
                  //     name="serviceImages"
                  //     uploadMultiple

                  //   />
                  // </>
                  <Typography variant="subtitle2" color={"red"}>
                    Please add a product to the store through bulk upload option
                  </Typography>
                )}
              </Form.Item>
            )}
          </Row>

          <Form.Item
            name="description"
            label="Description"
            hasFeedback
            style={{ width: "100%" }}
          >
            <RichTextEditor
              initialContent={editorContent1}
              onChange={handleEditorChange1}
            />
          </Form.Item>

          <Form.Item name="images" label="Images">
            <FileUpload
              title1="Upload Image"
              description="PDF, JPG, PNG file with max size of 5MB."
              setData={updateServiceImages}
              existingPreviews={imagePreviews}
              setExistingPreviews={setImagePreviews}
              name="serviceImages"
              uploadMultiple
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              className={Styles.addButton}
              htmlType="submit"
              onClick={() => setColorPickerVisible(false)}
              disabled={quantity && quantity > 10 ? true : false}
            >
              {/*  Show add button if it's new else show Save if it's editing */}
              {editingVariantId !== null ? "Save" : "Add"}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </Layout>
  );
};
export default AddNewProduct;
