import { Avatar, Button, Checkbox, Col, DatePicker, Drawer, Form, Input, notification, Radio, Row, Select, Space } from "antd";
import Cross  from "../../../assests/customIcons/Cross";
import RichTextEditor from "../../../components/RichText/Quill";
import Styles from "../../OrderPages/freelancerComponets/Table.module.css";
import { useEffect, useState } from "react";
import { _GET, _POST, getSupplierID } from "../../../common/commonFunc";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { Typography } from "@mui/material";

interface ContractDrawerParams {
    freelancer_id : string,
    visible: boolean,
    request_id : string,
    onClose: () => void;
    refreshData: () => void;
}

const AddContractDrawer  = ({freelancer_id, visible,request_id, onClose, refreshData}: ContractDrawerParams) => {
    const [form] = Form.useForm();
    const [visibleModal, setVisibleModal] = useState(visible);
    const [collabType, setCollabType] = useState<string | null>(null);
    const [fixedAmount, setFixedAmount] = useState<any>(null);
    const [contractType, setContractType] = useState<string | null>(null);
    const [editorContent, setEditorContent] = useState<any>(null);
    const [editorContentMore, setEditorContentMore] = useState<any>(null);
    const [myProducts, setMyProducts] = useState<any>([]);
    const [selectedProducts, setSelectedProducts] = useState<any>({});
    const [advancePayPercent, setAdvancePayPercent] = useState(10);

    const { Option } = Select;

    const validatePrice = async (_: any, value: any) => {
        if (!value) {
          return Promise.reject(new Error("Please enter a price!"));
        }
        const numValue = parseFloat(value);
        if (numValue <= 0) {
          return Promise.reject(new Error("Price must be greater than zero."));
        }
        const regex = /^\d{1,13}(\.\d{0,3})?$/;
        if (!regex.test(value)) {
          return Promise.reject(new Error("Price must be less than 1 trillion."));
        }
        return Promise.resolve();
      };

    const getProducts = async () => {
        try {
          const response = await _GET(
            `supplier/getAllAssignableProducts`,
            true,
            false
          );
          setMyProducts(response.data);
        } catch (error) {
          console.log("🚀 ~ getProducts ~ error:", error);
          setMyProducts([])
        }
      };
    
      useEffect(() => {
        getProducts();
      }, []);

      const handleCollabType = (event: any) => {
        setCollabType(event.target.value);
        setContractType(null);
        setSelectedProducts({})
      };

      const handleContractType = (event: any) => {
        setContractType(event.target.value);
        setSelectedProducts({})
      };

      const handleFixedAmtChange = (fixedAmt: any) => {
        setFixedAmount(fixedAmt)
      }

      const handleCommissionChange = (productId: any, commissionRate: any) => {
        setSelectedProducts((prev: any) => ({
          ...prev,
          [productId]: {
            ...prev[productId],
            commission_rate: commissionRate.toString(),
          },
        }));
      };
    
      const handlelumpSumChange = (productId: any, fixed_amount: any) => {
        setSelectedProducts((prev: any) => ({
          ...prev,
          [productId]: {
            ...prev[productId],
            fixed_amount: fixed_amount.toString(),
          },
        }));
      };
    
      const handleTargetQtyChange = (productId: any, targetQuantity: any) => {
        setSelectedProducts((prev: any) => ({
          ...prev,
          [productId]: {
            ...prev[productId],
            target_quantity: targetQuantity.toString(),
          },
        }));
      };

      const supplierID = getSupplierID();

      const handleSelectProduct = (productId: any, checked: any) => {
        setSelectedProducts((prev: any) => {
          const updatedProducts = checked
            ? {
              ...prev,
              [productId]: {
                product_id: productId,
                selected: checked,
                commission_rate: prev[productId]?.commission_rate || 0,
              },
            }
            : Object.keys(prev).reduce((acc: any, key: any) => {
              if (key !== productId) {
                acc[key] = prev[key];
              }
              return acc;
            }, {});
    
          return updatedProducts;
        });
      };


      const handlePercentageChange = (value: any) => {
        setAdvancePayPercent(value);
      };

      const handlePercentageChange_np = (productId: any, advancePercentage: any) => {
        // console.log("advance%", advancePercentage)
        setSelectedProducts((prev: any) => ({
          ...prev,
          [productId]: {
            ...prev[productId],
            advance_amount_percentage: advancePercentage,
          },
        }));
      };

    const closeModal = () => {
        onClose();
        setVisibleModal(false);
        form.resetFields();
        setCollabType(null);
        setFixedAmount(null);
        setContractType(null);
        setEditorContent(null);
        setEditorContentMore(null)
      };

      const handleEditorChange = (content: any) => {
        setEditorContent(content);
        const converter = new QuillDeltaToHtmlConverter(content.ops, {});
        const html = converter.convert();
      };
    
      const handleEditorChangeMore = (content: any) => {
        setEditorContentMore(content);
        const converter = new QuillDeltaToHtmlConverter(content.ops, {});
        const html = converter.convert();
      };
    
      const handleSubmit = async (formValues: any) => {
    
        if (collabType === 'non_product_based') {
          if (fixedAmount === null || fixedAmount === "") {
            notification.error({
              message: "Error",
              description: "Please enter the fixed amount",
            });
            return;
          }
        }
    
        if (collabType === null || collabType === "") {
          notification.error({
            message: "Error",
            description: "Please select a collaboration type",
          });
          return;
        }
    
        if (collabType === 'product_based') {
          if (contractType === null || contractType === "") {
            notification.error({
              message: "Error",
              description: "Please select a contract type",
            });
            return;
          }
          if (Object.keys(selectedProducts).length === 0) {
            notification.error({
              message: "Error",
              description: "Please select at least one product",
            });
            return;
          }
        }
    
    
        const productsArray: any = Object.values(selectedProducts)
          .filter((product: any) => product.selected)
          .map(({ product_id, commission_rate, target_quantity, fixed_amount, advance_amount_percentage }: any) => ({
            product_id,
            product_name: myProducts.find((p: any) => p.product_id === product_id)
              ?.product_name,
            commission_rate: contractType === "fixed_amount" ? null : parseFloat(commission_rate),
            target_quantity: target_quantity || 0,
            fixed_amount: contractType === 'commission_percentage' ? null : fixed_amount,
            contract_type: contractType,
            advance_amount_percentage: contractType === 'commission_percentage' ? 0.0 : advance_amount_percentage ?? 10,
          }));
    
        let payload
    
    
        if (collabType === 'non_product_based') {
          payload = {
            supplier_id: supplierID,
            freelancer_id: freelancer_id,
            products: [],
            collaboration_interest_expectation: editorContent,
            collaboration_detailed_info: editorContentMore,
            contractMode: collabType,
            non_product_fixed_amount: parseFloat(fixedAmount),
            advance_amount_percentage: advancePayPercent
    
          };
    
          console.log("value of fixed amount", fixedAmount)
        } else {
          payload = {
            supplier_id: supplierID,
            freelancer_id: freelancer_id,
            products: productsArray,
            collaboration_interest_expectation: editorContent,
            collaboration_detailed_info: editorContentMore,
            contractMode: collabType,
    
          }
        }
    
        console.log("payload in send collaboration", payload)
        try {
          const response: any = await _POST(
            `supplier/assignContracts/${request_id}`,
            payload,
            false
          );
          if (response.data === true) {
            showSuccessCustomModal({
              title: "Contract Added successfully",
              smallText: "Your Contract has been added successfully.",
              buttonText: "Continue",
            });
            refreshData();
            setVisibleModal(false);
            closeModal();
            form.resetFields();
            setEditorContent(null);
            setEditorContentMore(null);
            setSelectedProducts({});
          } else {
            notification.error({
              message: "Error",
              description: response.message,
            });
          }
        } catch (error) {
          console.log("🚀 ~ handleSubmit ~ error:", error);
        }
      };

    return (
        <Drawer
        title="Add New Contract"
        placement="right"
        closable={false}
        onClose={closeModal}
        open={visibleModal}
        key="right"
        width={"580px"}
        height={"100%"}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={closeModal} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        <div>
          <Form layout="vertical"
           form={form}
           onFinish={handleSubmit}
           className={Styles.formClass}
           >
            <Form.Item
              name="collaboration_interest_expectation"
              label="Contract interest expectation"
            >
              <RichTextEditor
                initialContent={editorContent}
                onChange={handleEditorChange}
              />
            </Form.Item>
            <Form.Item
              name="collaboration_detailed_info"
              label="Detailed Information on Promotional Product"
            >
              <RichTextEditor
                initialContent={editorContentMore}
                onChange={handleEditorChangeMore}
              />
            </Form.Item>


            <Form.Item name="contract_type" label="Contract model" required={true}>
              <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>

                <Radio.Group onChange={handleCollabType} value={collabType}>
                  <Radio value="product_based"> Product based Contract</Radio>
                  <Radio value="non_product_based">  Non Product based Contract</Radio>
                </Radio.Group>
              </div>
            </Form.Item>


            {collabType === "product_based" && (
              <Form.Item name="contract_type" label="Contract type" required={true}>
                <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>
                  <Radio.Group onChange={handleContractType} value={contractType}>
                    <Radio value="commission_percentage">Commission Based</Radio>
                    <Radio value="fixed_amount"> Fixed Price</Radio>
                  </Radio.Group>
                </div>
              </Form.Item>
            )}

            {collabType === "non_product_based" && (
              <Row gutter={16}>
                <Col span={12} style={{ marginBottom: "20px" }}>
                  <Form.Item
                    label={
                      <span>
                        Fixed Amount (BHD)
                      </span>
                    }
                    name="fixed_amount"
                    rules={[
                      { required: true, message: "" },

                      {
                        validator: validatePrice,
                      },
                    ]}
                  >
                    <Input
                      className={Styles.formInput}
                      placeholder="Enter Amount"
                      value={fixedAmount || ""}
                      onChange={e => handleFixedAmtChange(e.target.value)}
                      type="number"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="advance_pay_percentage"
                    label={
                      <span>
                        <span style={{ color: "red" }}>*</span> Select Advance Payment (%)
                      </span>
                    }
                  >
                    <Select
                      value={advancePayPercent}
                      onChange={handlePercentageChange}
                      style={{ width: 200 }}
                      className={Styles.formSelect}
                    >
                      <Option value={10}>10%</Option>
                      <Option value={25}>25%</Option>
                      <Option value={50}>50%</Option>
                      <Option value={75}>75%</Option>
                      <Option value={100}>100%</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

            )}

            {contractType && (
              <Form.Item name="products" label="Products">
                <div style={{ backgroundColor: "white", maxHeight: "300px", overflowY: "scroll" }} className={Styles.productList}>
                  {Array.isArray(myProducts) &&
                    myProducts.map(product => (
                      <div key={product.product_id} className={Styles.productListItem}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Checkbox
                            checked={selectedProducts[product.product_id]?.selected || false}
                            onChange={e => handleSelectProduct(product.product_id, e.target.checked)}
                            className={Styles.checkbox}
                          >
                            <Avatar src={product.prod_images[0]} />
                            {" "}
                            {product.product_name}
                          </Checkbox>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {contractType === 'commission_percentage' && (
                            <Input
                              className={Styles.formInput}
                              placeholder="Commission rate(%)"
                              type="number"
                              value={selectedProducts[product.product_id]?.commission_rate || ""}
                              onChange={e => handleCommissionChange(product.product_id, e.target.value)}
                              disabled={!selectedProducts[product.product_id]?.selected}
                              required={selectedProducts[product.product_id]?.selected}
                            />
                          )}
                          {(contractType === 'fixed_amount') && (
                            <>
                            <Input
                              className={Styles.formInput}
                              placeholder="Fixed amount"
                              type="number"
                              value={selectedProducts[product.product_id]?.fixed_amount || ""}
                              onChange={e => handlelumpSumChange(product.product_id, e.target.value)}
                              disabled={!selectedProducts[product.product_id]?.selected}
                              required={selectedProducts[product.product_id]?.selected}
                            />
                            
                            <Select
                              value={
                                selectedProducts[product.product_id]?.advance_amount_percentage ||
                                10
                              }
                              onChange={(value) => handlePercentageChange_np(product.product_id, value)}
                              style={{ width: 150 }}
                              className={Styles.formSelect}
                              placeholder={!selectedProducts[product.product_id]?.selected ? "Advance %" : "%"}
                              disabled={!selectedProducts[product.product_id]?.selected}
                            >
                              <Option value={10}>10%</Option>
                              <Option value={25}>25%</Option>
                              <Option value={50}>50%</Option>
                              <Option value={75}>75%</Option>
                              <Option value={100}>100%</Option>
                            </Select>
                          
                            </>
                          )}
                          <Input
                            className={Styles.formInput}
                            placeholder="Target Qty"
                            type="number"
                            value={selectedProducts[product.product_id]?.target_quantity || ""}
                            onChange={e => handleTargetQtyChange(product.product_id, e.target.value)}
                            disabled={!selectedProducts[product.product_id]?.selected}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </Form.Item>
            )}

            <Form.Item>
              <Button htmlType="submit"
               className={Styles.addButton}
               disabled={collabType === 'product_based' && (!myProducts || myProducts.length === 0)}
               >
                Send Request
              </Button>
              {collabType === 'product_based' && (!myProducts || myProducts.length === 0) && <Typography variant="caption" color={"red"}>Please add more products to the store to add this contract</Typography>}
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    )

}

export default AddContractDrawer;