import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  styled,
  TooltipProps,
  Tooltip,
  tooltipClasses,
  IconButton,
  Collapse,
} from "@mui/material";
import { Button, Input, message, notification, Popconfirm, Tabs, Typography } from "antd";
import { Delete, Filter, Visibility } from "@mui/icons-material";
import Styles from "../../OrderPages/freelancerComponets/Table.module.css";
import { _PATCH, _POST } from "../../../common/commonFunc";
import { CollabDetailsDrawer } from "./CollabDetailsDrawer";
import { useHistory } from "react-router";
import { ContractDetailsDrawer } from "./ContractDetailsDrawer";
import RejectContractModal from "./RejectContractModal";
import expandLight from "../../../assests/expandLight.svg";
import expandDark from "../../../assests/expandDark.svg";
import ExpandedRowContractTable from "./ExpandedRowContractTable";
import ReturnAcceptedProduct from "../../OrderPages/CustomerPage/ReturnAcceptedProduct";
import axios from "axios";

const CollaborationTable = ({ headers, data, status, fetchData }: any) => {
  const [page, setPage] = useState(0);
  const [expRowPage, setExpRowPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expRowsPerPage, setExpRowsPerPage] = useState(3);
  const [selectedDrawerService, setSelectedDrawerService] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [collabOpen, setCollabOpen] = useState(false);
  const [returnOpen, setReturnOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("");
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [rejectContactId, setRejectContactId] = useState("")
  const [rejectContactMode, setRejectContactMode] = useState("")
  const [expandedRow, setExpandedRow] = useState(null);
  const [contracts, setContracts] = useState([])
  const [qty, setQty] = useState(0)
  const [inventoryId, setInventoryId] = useState("")
  const history = useHistory();

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));

  const contractHeaders = [{ id: "contract_id", label: "CONTRACT ID", width: "10%" },
  {
    id: "contract_text",
    label: "CONTRACT TEXT",
    width: "15%",
  },
  { id: "contract_type", label: "CONTRACT TYPE", width: "10%" },
  { id: "contract_mode", label: "CONTRACT MODE", width: "10%" },
  { id: "contract_status", label: "CONTRACT STATUS", width: "5%" },
  { id: "fixed_amount", label: "AMOUNT ", width: "10%" },
  { id: "payment_status", label: "PAYMENT STATUS", width: "10%" },
  { id: "action", label: "ACTION", width: "30%" }]

  const onOpen = (row: any) => {
    setOpen(true);
    setSelectedDrawerService(row);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedDrawerService([]);
  };

  const onCollabOpen = (row: any, type: string) => {
    setCollabOpen(true);
    setSelectedDrawerService(row);
    setDrawerType(type);
  };
  const onCollabClose = () => {
    setCollabOpen(false);
    setSelectedDrawerService([]);
    setDrawerType("");
  };


  const handleMarkComplete = async (row: any) => {
    try {
      const completed = await _POST(`supplier/freelacner/completeContract?contractId=${row.contract_id}&contract_mode=${row.contract_mode}`, {}, true);
      if (completed.status) {
        notification.open({
          type: "success",
          message: "Contact Marked Completed",
          description: "This contract has been completed successfully.",
        });
        fetchData();
      } else {
        notification.open({
          type: "error",
          message: "Unable to Marke the contract complete",
          description: "This contract was not marked complete.",
        });
      }
    } catch (error) {
      console.error(error);
    }


  }

  const handleTerminateContract = async (row : any) => {
   console.log("row is : ", row)
    // try {
    //   const res = await axios.get(
    //     `https://testing.theoctopus.ai:8443/ /supplier/terminateContract?contract_id=Contract_c85d0fa7-a32f-45a0-aa64-2f36386f1fa3&contract_mode=product_based`
    //   );
    //   console.log("response is : ", res);
      
    //   return res.data.data[0];
    // } catch (error) {
    //   console.error("Error fetching product data:", error);
    //   return {};
    // }
  }

  const handleReturnProduct = (row : any) => {
    setReturnOpen(true)
    setQty(row.available_quantity)
    setInventoryId(row.assigned_inventory_id)
  }


  const handleAcceptProduct = async (contract_id: string) => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/upload-sign-contract/${contract_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Product Collaboration Signed !!",
            description: "Your Collaboration has been signed Successfully",
          });
          fetchData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Created !!",
            description: "Your Collaboration was not created ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRejectProduct = async (contract_id: string) => { };

  const handleAcceptContract = async (contractId: string) => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/freelancer/signNonProductContract/${contractId}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Contract Signed!",
            description: "Your contract has been signed successfully",
          });
          fetchData();
        } else {
          notification.open({
            type: "error",
            message: "Contract Not signed !!",
            description: "Your contract was not signed ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRejectContract = async (contract_id: string, contract_mode: string) => {
    setOpenRejectModal(true)
    setRejectContactId(contract_id)
    setRejectContactMode(contract_mode)
  }

  const closeRejectContractModal = () => {
    setOpenRejectModal(false)
    setRejectContactId("")
    setRejectContactMode("")
  }

  const handleAcceptRequest = async (id: string) => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/accept-collaboration-request/${id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Acccepted !!",
            description: "Your Collaboration has been established Successfully",
          });
          fetchData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Accepted !!",
            description: "Your Collaboration was not accepted ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRejectRequest = async (id: string) => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/reject-collaboration-request/${id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Rejected !!",
            description: "Your Collaboration has been rejected Successfully",
          });
          fetchData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Rejected !!",
            description: "Your Collaboration was not rejected ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const extendCollab = async (id: any) => {
    console.log("🚀 ~ extenddCollab ~ id:", id);
    const payload = {};
    try {
      const response = await _POST(
        `supplier/extend-collaboration-request/${id}`,
        payload,
        true
      );
      if (response.status === true && response.statusCode === 200) {
        message.success("Collaboration extended successfully");
        fetchData();
      } else {
        message.error(
          response.data && response.statusCode
            ? response.message
            : "Extend Collaboration failed"
        );
      }
    } catch (error) {
      console.log("🚀 ~ extendCollab ~ error:", error);
    }
  };

  const endCollab = async (id: any) => {
    console.log("🚀 ~ endCollab ~ id:", id);
    const payload = {};
    try {
      const response = await _POST(
        `supplier/end-collaboration-request/${id}`,
        payload,
        true
      );
      if (response.status === true && response.statusCode === 200) {
        message.success("Collaboration ended successfully");
        fetchData();
      } else {
        message.error(
          response.data && response.message
            ? response.message
            : "Collaboration end failed"
        );
      }
    } catch (error) {
      console.error("Error completing service:", error);
    }
  };

  const renderActionButtons = (row: any, type: string) => {
    console.log("checking row inside render action button", row);
    if (type !== "contract") {
      switch (status) {
        case "pendingCollab":
          return (
            <div className={Styles.actionButtons}>
              <Popconfirm
                title="Are you sure you want to accept this collaboration?"
                onConfirm={() => handleAcceptRequest(row?.request_id)}
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.reschedule}>Accept</Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to reject this collaboration?"
                onConfirm={() => handleRejectRequest(row?.request_id)}
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.cancel}>Reject</Button>
              </Popconfirm>
              <Button
                className={Styles.eye}
                onClick={() => onCollabOpen(row, "collab")}
              >
                <Visibility />
              </Button>
            </div>
          );
        case "acceptedCollab":
          return (
            <div className={Styles.actionButtons}>
              <Popconfirm
                title="Are you sure you want to extend this collaboration?"
                onConfirm={() => extendCollab(row?.request_id)}
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.reschedule}>Extend</Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to end this collaboration?"
                onConfirm={() => endCollab(row?.request_id)}
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.cancel}>End</Button>
              </Popconfirm>
              <Button
                className={Styles.eye}
                onClick={() =>
                  history.push(`/freelancer/collab-details/${row?.supplier_id}`, {
                    data: {
                      freelancer_profile_pic: row.supplier_profile_pic,
                      freelancer_first_name: row.supplier_first_name,
                      freelancer_last_name: row.supplier_last_name,
                      collaboration_start_date: row.collaboration_start_date,
                      collaboration_duration_days:
                        row.collaboration_duration_days,
                      collaboration_days_left: row.collaboration_days_left,
                    },
                  })
                }
              >
                <Visibility />
              </Button>
              <IconButton
                onClick={() => handleRowClick(row.request_id)}
                style={{ height: "35px", width: "35px" }}
              >
                <img
                  src={expandedRow === row.request_id ? expandDark : expandLight}
                  alt="Expand"
                />
              </IconButton>
            </div>
          );
        case "acceptedContract":
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {row.is_advance_amount_paid && (
                <>
                <Popconfirm
                  title="Are you sure you want to mark this contract complete?"
                  onConfirm={() => { handleMarkComplete(row) }}
                  okText="Yes"
                  cancelText="No"
                >
                  {!row.is_balance_amount_paid && <Button className={Styles.reschedule}>
                    Mark Contract Complete
                  </Button>}
                </Popconfirm>
                {!row.is_advance_amount_paid && <Popconfirm
                  title="Are you sure you want to terminate this contract?"
                  onConfirm={() => { handleTerminateContract(row) }}
                  okText="Yes"
                  cancelText="No"
                >
                 <Button className={Styles.reschedule}>
                    Terminate
                  </Button>
                </Popconfirm>}
                </>
              )}
              <Button className={Styles.eye} onClick={() => onOpen(row)}>
                <Visibility />
              </Button>
            </div>
          );
        case "acceptedProd":
          return (
            <div className={Styles.actionButtons}>
              <Button className={Styles.cancel} onClick={() => handleReturnProduct(row)}>Return Product</Button>
              <Popconfirm
                  title="Are you sure you want to terminate this contract?"
                  onConfirm={() => { handleTerminateContract(row) }}
                  okText="Yes"
                  cancelText="No"
                >
                  {!row.is_balance_amount_paid && <Button className={Styles.reschedule}>
                    Terminate
                  </Button>}
                </Popconfirm>
              <Button
                className={Styles.eye}
                onClick={() => onCollabOpen(row, "prodDetails")}
              >
                <Visibility />
              </Button>
            </div>
          );
        case "pendingContract":
          return (
            <div className={Styles.actionButtons}>
              <Popconfirm
                title="Are you sure you want to accept this contract?"
                onConfirm={() => handleAcceptContract(row?.contract_id)}
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.reschedule}>Accept</Button>
              </Popconfirm>
              <Button className={Styles.cancel}
                onClick={() => handleRejectContract(row?.contract_id, row?.contract_mode)}>
                Reject</Button>

              <Button className={Styles.eye} onClick={() => onOpen(row)}>
                <Visibility />
              </Button>
            </div>
          );
        case "pendingProd":
          return (
            <div className={Styles.actionButtons}>
              <Popconfirm
                title="Are you sure you want to accept this product?"
                onConfirm={() => handleAcceptProduct(row?.contract_id)}
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.reschedule}>Accept</Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to reject this product?"
                onConfirm={() => handleRejectProduct(row?.contract_id)}
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.cancel}>Reject</Button>
              </Popconfirm>
              <Button
                className={Styles.eye}
                onClick={() => onCollabOpen(row, "prodDetails")}
              >
                <Visibility />
              </Button>
            </div>
          );
        case "rejected":
          return (
            <div className={Styles.actionButtons}>
              <Typography style={{ marginRight: "40px", color: 'red' }}>REJECTED</Typography>
              <Button
                className={Styles.eye}
                onClick={() => onCollabOpen(row, "rejectedCollab")}
              >
                <Visibility />
              </Button>
            </div>
          );
        default:
          return null;
      }
    } else {
      return (
        <div className={Styles.actionButtons}>
          <Popconfirm
            title="Are you sure you want to accept this collaboration?"
            onConfirm={() => handleAcceptRequest(row?.request_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button className={Styles.reschedule}>Accept</Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure you want to reject this collaboration?"
            onConfirm={() => handleRejectRequest(row?.request_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button className={Styles.cancel}>Reject</Button>
          </Popconfirm>
          <Button
            className={Styles.eye}
            onClick={() => onCollabOpen(row, "collab")}
          >
            <Visibility />
          </Button>
        </div>
      );
    }
  };


  const renderExpandedRow = (row: any) => {
    return (
      <div className={Styles.expandedRow1} style={{ padding: "0.4rem" }}>
        <div style={{ padding: "0.8rem", backgroundColor: "#F5F4FF" }}>

          <ExpandedRowContractTable
            headers={contractHeaders}
            data={[...(row?.non_product_based_contracts ?? []), ...(row?.product_based_contracts ?? [])]}
            status="acceptedProd"
            fetchData={fetchData}

          />
        </div>
      </div>

    );
  };


  function getPlainText(text: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    return doc.body.textContent || "";
  }

  const handleViewMore = () => {
    setRowsPerPage((prev) => prev + 3);
  };

  const handleShowLess = () => {
    setPage(0);
    setRowsPerPage(3);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExpChangePage = (event: unknown, newPage: number) => {
    setExpRowPage(newPage);
  };


  const handleExpChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExpRowsPerPage(parseInt(event.target.value, 10));
    setExpRowPage(0);
  };

  const renderProducts = (product: any) => {
    return (
      <>
        {product?.length > 0 ? (
          product?.map((p: any, index: number) => {
            return (
              <img
                key={index}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  margin: "0.8px",
                  border: "2px solid #d8d8d8",
                }}
                src={p.product_images[0]}
                alt=""
              />
            );
          })
        ) : (
          <span>-</span>
        )}
      </>
    );
  };

  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const renderId = (id: string) => {
    return (
      <LightTooltip
        title={
          <>
            <div style={{ fontWeight: "bold" }}>Click to Copy :</div>
            <br />
            <div>{id}</div>
          </>
        }
        placement="bottom"
        arrow
      >
        <span
          onClick={(event) => copyToClipboard(id, event)}
          style={{
            cursor: "pointer",
          }}
        >
          {formatId(id)}
        </span>
      </LightTooltip>
    );
  };

  const renderDesc = (text: string) => {
    return (
      <LightTooltip
        title={<>{getPlainText(text ?? " ")}</>}
        placement="bottom"
        arrow
      >
        <div>{text ? formatDesc(getPlainText(text ?? " ")) : "-"}</div>
      </LightTooltip>
    );
  };

  const SupplierDetails = (firstname: any, lastname: any, pic: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "left",
          justifyContent: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        {renderAvatar(firstname, lastname, pic)}
        <p>{`${firstname} ${lastname}`}</p>
      </div>
    );
  };

  const formatDesc = (desc: any) => {
    const prefix = desc?.substring(0, 50);
    if (desc?.length > 50) {
      return `${prefix}${" ... "}`;
    }
    return `${prefix}`;
  };

  const renderAvatar = (fname: any, lname: any, picture: any) => {
    if (picture && picture !== "NULL") {
      return (
        <img
          src={picture}
          style={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
          }}
          alt="avatar"
        />
      );
    } else {
      return (
        <img
          src={"https://picsum.photos/200?random=8"}
          style={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
          }}
          alt="avatar"
        />
      );
    }
  };

  const formatId = (id: any) => {
    const prefix = id?.substring(0, 7);
    const start = id?.substring(8, 10);
    const end = id?.substring(id.length - 2);
    return `${prefix}${" : "}${start}..${end}`;
  };

  const copyToClipboard = async (text: any, event: any) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      notification.success({ message: "Request ID copied to clipboard" });
    } catch (err) {
      notification.error({ message: "Failed to copy Request ID" });
    }
    event.stopPropagation();
  };

  const handleRowClick = (requestId: any) => {
    setExpandedRow(expandedRow === requestId ? null : requestId);
  };

  return (
    <>
      <TableContainer component={Paper} className={Styles.tableContainer}>
        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableHead className={Styles.tableHeader}>
              <TableRow>
                {headers.map((header: any) => (
                  <TableCell
                    key={header.id}
                    style={{
                      width: header.width,
                      textAlign: "center",
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </div>

        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableBody className={Styles.tableBody}>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    style={{ textAlign: "center" }}
                  >
                    <h1> No data available</h1>
                  </TableCell>
                </TableRow>
              ) : (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => (
                    <>
                      <TableRow
                        key={index}
                        onClick={() => handleRowClick(row?.request_id ?? row?.collaboration_request_id)}
                        style={{
                          backgroundColor:
                            expandedRow === row.request_id ? "#30263E" : "",
                        }}
                        className={
                          expandedRow === row.request_id ? `${Styles.expanded}` : ""
                        }
                      >
                        {headers.map((header: any) => (
                          <TableCell
                            key={`${header.id}-${index}`}
                            style={{
                              width: header.width,
                              textAlign: "center",
                              color: expandedRow === row.request_id ? "white" : "",
                              backgroundColor:
                                expandedRow === row.request_id ? "#30263E" : "",
                            }}
                          >
                            {header.id === "collaboration_id" ? (
                              renderId(
                                row?.request_id ?? row?.collaboration_request_id
                              )
                            ) : header.id === "contract_id" ? (
                              renderId(row?.contract_id)
                            ) : header.id === "supplier_details" ? (
                              SupplierDetails(
                                row?.supplier_first_name,
                                row?.supplier_last_name,
                                row?.supplier_profile_pic
                              )
                            ) : header.id === "start_date" ? (
                              formatDate(row?.collaboration_start_date)
                            ) : header.id === "end_date" ? (
                              formatDate(row?.collaboration_end_date)
                            ) : header.id === "collab_duration" ? (
                              <>
                                {formatDate(row?.collaboration_start_date)}
                                <b> to </b>
                                {formatDate(row?.collaboration_end_date)}
                              </>
                            ) : header.id === "interest_expec" ? (
                              renderDesc(row?.collaboration_interest_expectation)
                            ) : header.id === "contract_text" ? (
                              renderDesc(row?.contract_text)
                            ) : header.id === "fixed_amount" ? (
                              <>
                                <Typography>
                                  <b>BHD</b> {row?.fixed_amount}
                                </Typography>{" "}
                              </>
                            ) : header.id === "detailed_info" ? (
                              renderDesc(row?.collaboration_detailed_info)
                            ) : header.id === "product_desc" ? (
                              renderDesc(row?.product_description)
                            ) : header.id === "products" ? (
                              renderProducts(row?.products)
                            ) : header.id === "product_details" ? (
                              row?.product_images && (
                                <img
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    border: "2px solid #d8d8d8",
                                  }}
                                  src={row?.product_images[0]}
                                  alt=""
                                ></img>
                              )
                            ) : header.id === "action" ? (
                              renderActionButtons(row, "collab")
                            ) : (
                              row[header.id]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            padding: 0,
                          }}
                          colSpan={headers.length}
                        >
                          <Collapse
                            in={expandedRow === row.request_id}
                            timeout="auto"
                            unmountOnExit
                          >
                            {renderExpandedRow(row)}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
              )}
            </TableBody>
          </Table>
          <div className={Styles.tableFooter}>
            <div className={Styles.viewControls}>
              {data.length > 3 && rowsPerPage > 3 && (
                <button onClick={handleShowLess} className={Styles.showLess}>
                  Show Less
                </button>
              )}
              {data.length > page * rowsPerPage + rowsPerPage && (
                <button onClick={handleViewMore} className={Styles.viewMore}>
                  View More
                </button>
              )}
            </div>
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200]}
              className={Styles.pagination}
            />
          </div>
        </div>
      </TableContainer>

      <ContractDetailsDrawer
        onOpen={open}
        onClose={onClose}
        contractDetails={selectedDrawerService}
        fetchData={fetchData}
      />

      <CollabDetailsDrawer
        open={collabOpen}
        onClose={onCollabClose}
        collabDetails={selectedDrawerService}
        fetchData={fetchData}
        type={drawerType}
      />

      <RejectContractModal
        isVisible={openRejectModal}
        onClose={closeRejectContractModal}
        contract_id={rejectContactId}
        contract_mode={rejectContactMode}
        fetchData={fetchData}
      />
             <ReturnAcceptedProduct
                isVisible={returnOpen}
                onClose={() => setReturnOpen(false)}
                // onSubmit={(reason) => handleReturnOrder(data.id, reason)}
                id={"ahihdaufyoadfyodifyio"}
                quantity={10}
            />
    </>
  );
};

export default CollaborationTable;
